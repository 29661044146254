const returntypes = [
  "GSTR-3B Quarterly",
  "GSTR-3B Monthly",
  "GSTR-1 Quarterly",
  "GSTR-1 Monthly",
  "IFF",
  "GSTR-9",
  "GSTR-4",
  "CMP-08"
];

export default returntypes;
