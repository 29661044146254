import React, { ComponentType } from "react";
//Redux Notifications
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Dashboard from "../../components/Dashboard";
import Icon from "../../components/Icon";
import routes from "../../constants/routes";
//import validGSTIN from "../../helpers/GSTValidationFunction";
import { ADD_GST, ADD_NOTIFICATION } from "../../store/types";
import TagManager from "react-gtm-module";
import MultiSelect from "../../components/MultiSelect";
import RequestProgress from "../../components/RequestProgress";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gstadd"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addGst: (gst: any) =>
    dispatch({
      type: ADD_GST,
      payload: { gst }
    }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & WithRouterProps;

//Main Classs
class AddClient extends React.Component<PropsFromRedux> {
  state: {
    GSTN: string;
    MultipleGSTIN: any;
    MultipleArrayGSTIN: any;
    logging: boolean;
    avlbGSTCredit: number;
    expiryDate: any;
    tagList: [];
    selectedTagId: string[];
    selectedTag: string[];
    showReqeustModal: boolean;
    requestId: string;
  };

  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      GSTN: "",
      MultipleGSTIN: [], //"27AJKPJ3977G2ZS\n27AJKPJ3977G2ZS",
      logging: false,
      MultipleArrayGSTIN: [],
      avlbGSTCredit: 0,
      expiryDate: "",
      tagList: [],
      selectedTagId: [],
      selectedTag: [""],
      showReqeustModal: false,
      requestId: ""
    };
  }

  /* addGst = () => {
    let isValid = validGSTIN(this.state.GSTN);
    if (isValid) {
      this.setState({ logging: true });
      agent.Gst.addGst(this.state.GSTN)
        .then((response: any) => {
          this.setState({ logging: false });
          this.props.history.push(
            routes.AgencyClients
          );
          (this.props as any).addGst(response.gst);
          (this.props as any).onNotify(
            "Added GSTIN",
            "Successfully added new GSTIN.",
            "success"
          );
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).onNotify(
            "Could not get GSTIN Data",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      (this.props as any).onNotify(
        "Incorrect GSTIN",
        "Please enter a valid GSTIN to proceed.",
        "danger"
      );
    }
  };
 */
  addMultipleGst = () => {
    let MultipleArrayGSTIN: string[] = this.state.MultipleGSTIN.split("\n");
    let isValid = true; //validGSTIN(this.state.GSTN); RP Pending Work - Have to validate the GSTIN in the array before processing
    if (isValid) {
      this.setState({ logging: true });
      agent.Gst.addmultipleGst(MultipleArrayGSTIN, this.state.selectedTag)
        .then((response: any) => {
          this.setState({ logging: false });
          /* this.props.history.push(
            routes.AgencyClients
          ); */
          this.setState({ requestId: response.importRequest }, () => {
            this.requestProgress(this.state.requestId);
          });
          //(this.props as any).addGst(response.gst);
          /* (this.props as any).onNotify(
            "GSTIN Import Request",
            "We have taken your request and will send you an email once all GSTIN is processed.",
            "success"
          ); */
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).onNotify(
            "Could not get GSTIN Data",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      (this.props as any).onNotify(
        "Incorrect GSTIN",
        "Please enter a valid GSTIN to proceed.",
        "danger"
      );
    }
  };

  redirect = () => {
    this.props.navigate?.(routes.AgencyClients);
  };

  getGSTCredit = () => {
    agent.Gst.getGstCredit()
      .then((response: any) => {
        this.setState({
          avlbGSTCredit: response.gstinCredits,
          expiryDate: response.expiry_date
        });
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).onNotify(
          "Could not connect to server",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  tagListing = () => {
    agent.Tag.getAll()
      .then((response: any) => {
        let finalTagList = response.tags.filter(
          (tag: any) => tag.active === true
        );
        //.map((tag: any) => tag.name);
        this.setState({ tagList: finalTagList });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: ev.target.value
      //[field]: ev.target.value.trim() RP Pending Work - Have to take care of trim in the case of multiple GSTIN
    });
  };

  handleValueTag = (value: any) => {
    let tagList = this.state.tagList;
    let selectedTags: any = value;
    let selectedTagObject: any = tagList.filter((tag: any) =>
      selectedTags.includes(tag.name)
    );
    let selectedTagId: any = selectedTagObject.map((tag: any) => tag._id);
    this.setState({ selectedTag: selectedTagId });
  };

  //onMount Load data
  componentDidMount() {
    this.getGSTCredit();
    this.tagListing();
  }

  requestModalSetOpen = (open: boolean) => {
    this.setState({
      showReqeustModal: open
    });
  };

  requestProgress = (requestId: string) => {
    this.setState({ id: requestId });
    this.requestModalSetOpen(true);
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <RequestProgress
          type="GSTIN"
          onLoad={() => {
            this.getGSTCredit();
          }}
          redirect={() => {
            this.redirect();
          }}
          requestModalSetOpen={this.requestModalSetOpen}
          state={this.state}
        />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 gstadd">
          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Add Client
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Enter the GST number of the client
                    <p></p>
                    <p>
                      You can add GST number which have GST type as Regular or
                      Composition.
                    </p>
                    <br></br>
                    <div className="font-medium text-base justify-center py-2 block text-gray-900">
                      Here are your plan details: -
                      <div className="text-left justify-center py-2 block text-sm font-medium text-gray-700">
                        <p>
                          Available GSTIN credits - {this.state.avlbGSTCredit}
                        </p>
                        <p>
                          Expiry Date -{" "}
                          {new Date(this.state.expiryDate).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form>
                  <div className="shadow sm:rounded-md ">
                    {/* <div className="shadow sm:rounded-md sm:overflow-hidden">
                    commented to show the dropdown of Tags. Have to find out the
                    option for showing scrolling in the box */}
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <div className="grid grid-cols-3 gap-6">
                        {/* <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="company_website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            GSTN
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="company_website"
                              value={this.state.GSTN}
                              onChange={this.updateState("GSTN")}
                              id="company_website"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              placeholder="08AAHCT6764E1Z1"
                            />
                          </div>
                        </div> */}
                      </div>
                      <div>
                        <label
                          htmlFor="about"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Enter one or more GSTIN to import
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <textarea
                            id="about"
                            name="about"
                            rows={10}
                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                            placeholder="You can enter multiple GSTIN in separate line to import as shown below: - &#10;08AAHCT6764E1Z1&#10;27AAACR5055K2Z6&#10;09AAACK4409J1ZI&#10;27AAACS8577K2ZO&#10;29AAACC6106G1ZX"
                            value={this.state.MultipleGSTIN}
                            onChange={this.updateState("MultipleGSTIN")}
                          />
                        </div>
                        <div className="py-3">
                          <MultiSelect
                            heading="Select Tags"
                            list={this.state.tagList.map(
                              (tag: any) => tag.name
                            )}
                            handleValue={this.handleValueTag}
                            defaultValue={[]}
                            placeholder="Showing All Tags"
                          ></MultiSelect>
                        </div>

                        <p className="mt-2 text-sm text-gray-700">
                          Paste all the client GSTIN in the box which you like
                          to import. Each GSTIN should be in new line.
                          <br />
                          <br />
                          QRMP preference along with GST return status from Jan
                          2021 till date will be imported automatically.
                        </p>
                      </div>
                    </div>
                    <div className="space-x-4 px-4 py-3 bg-gray-50 text-right sm:px-6">
                      {/* <button
                        type="button"
                        onClick={this.addGst}
                        disabled={this.state.logging}
                        className={
                          this.state.logging
                            ? "inline-flex disabled justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                            : "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                        }
                      >
                        {this.state.logging ? <Icon name="loading" /> : null}
                        Save Single GSTIN
                      </button> */}
                      <div className="text-right">
                        <button
                          type="button"
                          onClick={this.addMultipleGst}
                          disabled={this.state.logging}
                          className={
                            this.state.logging
                              ? "inline-flex disabled justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                              : "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                          }
                        >
                          {this.state.logging ? <Icon name="loading" /> : null}
                          Save Multiple GSTIN
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(AddClient) as ComponentType;
