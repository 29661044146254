import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import agent from "../agent";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../store/types";
import routes from "../constants/routes";
import { WithRouterProps } from "../helpers/withRouter";
//import { ExclamationIcon, XMarkIcon } from "@heroicons/react/24/outline";
//import { request } from "http";
//import { roundToNearestMinutes } from "date-fns/esm";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> &
  Partial<WithRouterProps>;

type Props = PropsFromRedux & {
  onLoad?: () => void;
  redirect?: () => void;
  requestModalSetOpen?: (open: boolean) => void;
  state?: any;
  type?: any;
};

class RequestProgress extends React.Component<Props> {
  state: {
    logging: boolean;
    totalRecords: number;
    processedRecords: number;
    alreadyFiledReturns: number;
    skippedReturns: number;
    requestStatus: string;
    requestType: string;
    requestPeriod: string;
    requestTask: string;
    requestCreated: string;
    requestUpdated: string;
    wrongGstinCount: number;
    alreadyPresentGstinCount: number;
    gstinSavedCount: number;
    gstinNotSavedDuetoCreditsCount: number;
    otherTypeOfGstinCount: number;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      logging: false,
      totalRecords: 0,
      processedRecords: 0,
      alreadyFiledReturns: 0,
      skippedReturns: 0,
      requestStatus: "",
      requestType: "",
      requestPeriod: "",
      requestTask: "",
      requestCreated: "",
      requestUpdated: "",
      wrongGstinCount: 0,
      alreadyPresentGstinCount: 0,
      gstinSavedCount: 0,
      gstinNotSavedDuetoCreditsCount: 0,
      otherTypeOfGstinCount: 0
    };
  }

  requestProgress = () => {
    if ((this.props as any).state.requestId) {
      this.setState({
        logging: true
      });
      return this.props.type === "ReturnStatus"
        ? agent.Request.getRequestStatus((this.props as any).state.requestId)
            .then((response: any) => {
              this.setState({
                logging: false,
                totalRecords: response.result.totalToRecordsToUpdate,
                processedRecords: response.result.statusUpdated,
                alreadyFiledReturns: response.result.alreadyFiled,
                skippedReturns: response.result.skipped,
                requestStatus: response.status,
                requestType: response.importType,
                requestPeriod: response.period,
                requestTask: response.task,
                requestCreated: response.createdAt,
                requestUpdated: response.updatedAt
              });
              this.onLoad();
            })
            .catch((err: any) => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
              this.setOpen(false);
            })
        : this.props.type === "QRMP"
        ? agent.Request.getRequestStatus((this.props as any).state.requestId)
            .then((response: any) => {
              this.setState({
                logging: false,
                totalRecords: response.result.totalToRecordsToUpdate,
                processedRecords:
                  response.result.statusUpdated + response.result.alreadyFiled,
                requestStatus: response.status,
                requestType: response.importType,
                requestPeriod: response.period,
                requestTask: response.task,
                requestCreated: response.createdAt,
                requestUpdated: response.updatedAt
              });
              this.onLoad();
            })
            .catch((err: any) => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
              this.setOpen(false);
            })
        : this.props.type === "UpdateGSTIN"
        ? agent.Request.getRequestStatus((this.props as any).state.requestId)
            .then((response: any) => {
              this.setState({
                logging: false,
                totalRecords: response.result.totalToRecordsToUpdate,
                processedRecords:
                  response.result.alreadyFiled + response.result.statusUpdated,
                requestStatus: response.status,
                requestType: response.importType,
                requestPeriod: response.period,
                requestTask: response.task,
                requestCreated: response.createdAt,
                requestUpdated: response.updatedAt
              });
              this.onLoad();
            })
            .catch((err: any) => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
              this.setOpen(false);
            })
        : this.props.type === "GSTIN"
        ? agent.Request.getRequestStatus((this.props as any).state.requestId)
            .then((response: any) => {
              this.setState({
                logging: false,
                totalRecords: response.gstin.length,
                processedRecords:
                  response.result.wrongGstinCount +
                  response.result.alreadyPresentGstinCount +
                  response.result.gstinSavedCount +
                  response.result.gstinNotSavedDuetoCreditsCount +
                  response.result.otherTypeOfGstinCount,
                requestStatus: response.status,
                requestType: response.importType,
                requestPeriod: response.period,
                requestTask: response.task,
                requestCreated: response.createdAt,
                requestUpdated: response.updatedAt,
                wrongGstinCount: response.result.wrongGstinCount,
                alreadyPresentGstinCount:
                  response.result.alreadyPresentGstinCount,
                gstinSavedCount: response.result.gstinSavedCount,
                gstinNotSavedDuetoCreditsCount:
                  response.result.gstinNotSavedDuetoCreditsCount,
                otherTypeOfGstinCount: response.result.otherTypeOfGstinCount
              });
              this.onLoad();
            })
            .catch((err: any) => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
              this.setOpen(false);
            })
        : this.setState({
            logging: false
          });
    } else {
      return this.setOpen(false);
    }
  };

  setOpen = (open: boolean) => {
    (this.props as any).requestModalSetOpen(open);
  };

  componentDidMount() {
    setInterval(
      () =>
        this.state.requestStatus !== "Completed"
          ? this.requestProgress()
          : null,
      3000
    );
  }

  //onMount Load data
  componentDidUpdate = (prevProps: any, prevState: any) => {
    if ((this.props as any).state.requestId !== prevProps.state.requestId) {
      this.requestProgress();
    }

    /* if (prevState.requestStatus !== "Running") {
      setInterval(() => this.requestProgress(), 5000);
      console.log("request status", this.state.requestStatus);
    } */
  };

  onLoad = () => {
    (this.props as any).onLoad();
  };

  redirect = () => {
    (this.props as any).redirect();
  };

  render() {
    //console.log("request status", this.state.requestStatus);
    return (
      <>
        <Transition.Root
          show={(this.props as any).state.showReqeustModal}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => this.setOpen(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  {/* <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => this.setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div> */}
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div> */}
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-medium text-gray-900"
                      >
                        {this.props.type === "ReturnStatus"
                          ? "GST Return Request Status"
                          : this.props.type === "QRMP"
                          ? "QRMP Request Status"
                          : this.props.type === "UpdateGSTIN"
                          ? "GSTIN Update"
                          : "GSTIN Import Request Status"}
                      </Dialog.Title>
                    </div>
                  </div>
                  <div>
                    <p className="mt-4 text-sm font-medium text-gray-900">
                      Current Status: {this.state.requestStatus}
                      {this.state.requestType === "ReturnStatus" ||
                      this.state.requestType === "QRMP" ? (
                        <div> Period: {this.state.requestPeriod}</div>
                      ) : null}
                      {this.state.requestType === "ReturnStatus" ? (
                        <div> Task: {this.state.requestTask}</div>
                      ) : null}
                      {this.state.requestType === "GSTIN" ? (
                        <div>
                          New GSTIN Saved: {this.state.gstinSavedCount}
                          <br />
                          Already Present GSTIN :
                          {this.state.alreadyPresentGstinCount}
                          <br />
                          Wrong GSTIN : {this.state.wrongGstinCount}
                          <br />
                          Other Type of GSTIN :{" "}
                          {this.state.otherTypeOfGstinCount}
                          <br />
                          GSTIN Skipped due to shortage of Credit:{" "}
                          {this.state.gstinNotSavedDuetoCreditsCount}
                        </div>
                      ) : null}
                      <div>
                        Created at:{" "}
                        {new Date(
                          this.state.requestCreated
                        ).toLocaleDateString() +
                          " - " +
                          new Date(
                            this.state.requestCreated
                          ).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit"
                          })}
                      </div>
                      <div>
                        Last Updated at:{" "}
                        {new Date(
                          this.state.requestUpdated
                        ).toLocaleDateString() +
                          " - " +
                          new Date(
                            this.state.requestUpdated
                          ).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit"
                          })}
                      </div>
                    </p>
                    <div className="mt-6" aria-hidden="true">
                      <div className="bg-gray-200 rounded-full overflow-hidden">
                        <div
                          className="h-2 bg-indigo-600 rounded-full"
                          style={{
                            width:
                              (this.state.processedRecords +
                                (this.props.type === "ReturnStatus"
                                  ? this.state.skippedReturns
                                  : 0) /
                                  this.state.totalRecords) *
                                100 +
                              "%"
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-2 text-sm flex gap-4 items-center justify-between">
                      <span className="space-x-4">
                        <span>
                          Record Updated - {this.state.processedRecords}
                        </span>
                        {this.props.type === "ReturnStatus" && (
                          <span>
                            Record Skipped - {this.state.skippedReturns}
                          </span>
                        )}
                      </span>
                      <span>Total Records - {this.state.totalRecords}</span>
                    </div>

                    <div
                      className={`mt-2 text-sm text-left ${
                        this.state.requestStatus === "Completed" && "invisible"
                      }`}
                    >
                      Estimated time:{" "}
                      {Math.floor(
                        (this.state.totalRecords -
                          this.state.processedRecords) /
                          60
                      )}{" "}
                      Min{" "}
                      {Math.round(
                        this.state.totalRecords -
                          this.state.processedRecords -
                          Math.floor(
                            (this.state.totalRecords -
                              this.state.processedRecords) /
                              60
                          ) *
                            60
                      )}{" "}
                      Sec
                    </div>
                  </div>

                  <div className="mt-4">
                    {this.state.requestType === "ReturnStatus" ? (
                      <p className="text-xs text-gray-800">
                        Note: - You can close this page if you want. Your
                        request will be processed in background and you will get
                        an email once the status get updated.{" "}
                        <span className="font-bold text-red-500">
                          The system will skip those returns which was updated
                          in the last 15 minutes.
                        </span>
                      </p>
                    ) : this.state.requestType === "QRMP" ? (
                      <p className="text-xs text-gray-500">
                        Note:- You can close this page if you want. Your request
                        will be processed in background and you will get an
                        email once the status get updated.
                      </p>
                    ) : this.state.requestType === "UpdateGSTIN" ? (
                      <p className="text-xs text-gray-500">
                        Note:- You can close this page if you want. Your request
                        will be processed in background and you will get an
                        email once GSTIN get updated.
                      </p>
                    ) : (
                      <p className="text-xs text-gray-500">
                        Note:- You can close this page if you want. Your request
                        will be processed in background and you will get an
                        email once GSTIN get imported.
                      </p>
                    )}
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {/* <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => this.requestProgress()}
                    >
                      Delete
                    </button> */}
                    <button
                      type="button"
                      disabled={this.state.requestStatus !== "Completed"}
                      className={
                        this.state.requestStatus === "Completed"
                          ? "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                          : "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-300 cursor-not-allowed"
                      }
                      //className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={
                        this.state.requestType === "GSTIN"
                          ? () => this.redirect()
                          : () => this.setOpen(false)
                      }
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(RequestProgress);
