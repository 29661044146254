import { PlusIcon } from "@heroicons/react/24/solid";
import React, { ComponentType } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
// Pagination
import ReactPaginate from "react-paginate";
import { connect, ConnectedProps } from "react-redux";
// Routing imports
import { Link } from "react-router-dom";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
import SelectMenu from "../../components/SelectMenu";
//import years from "../../constants/years";
// Redux Notify
import { ADD_NOTIFICATION } from "../../store/types";
import "../style.css";
import TagManager from "react-gtm-module";
//import { string } from "prop-types";
//import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
//import { Combobox } from "@headlessui/react";
import MultiSelect from "../../components/MultiSelect";
import SearchList from "./searchList/index";
import quarters from "../../helpers/quarters";
import { downloadFile } from "../../helpers/downloadFile";
import itemsPerPage from "../../constants/itemsPerPage";
//import { check } from "prettier";
import RequestProgress from "../../components/RequestProgress";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "QRMP"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping

const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & WithRouterProps;

const qrmpOptions = [
  { name: "Monthly", value: "M" },
  { name: "Quarterly", value: "Q" },
  { name: "Not Checked", value: "NA" },
  { name: "Not Opted", value: "N" }
];

class QRMP extends React.Component<PropsFromRedux> {
  state: {
    loading: boolean;
    logging: boolean;
    QRMPDetails: any;
    totalRecords: number;
    displayQRMPDetails: any;
    allGSTIN: [];
    selectedTagId: string;
    modalOpen: boolean;
    selectedQuarter: string;
    exportIcon: string;
    tableHeadingReturnPeriod: string;
    selectedTag: string[];
    selectedQRMP: string[];
    chunkSize: number;
    currPage: any;
    tagList: [];
    searchText: string;
    typing: boolean;
    typingTimeout: number;
    //currentYear: any;
    showReqeustModal: boolean;
    requestId: string;
  };

  constructor(props: PropsFromRedux) {
    super(props);

    this.state = {
      loading: false,
      logging: false,
      QRMPDetails: [],
      totalRecords: 0,
      displayQRMPDetails: [],
      allGSTIN: [],
      selectedTagId: "",
      modalOpen: false,
      selectedQuarter: this.props?.searchParams?.has("quarter")
        ? this.props?.searchParams?.get("quarter") || quarters()[0]
        : quarters()[0],
      tableHeadingReturnPeriod: quarters()[0],
      exportIcon: "outline/download",
      selectedTag: [""],
      selectedQRMP: this.props?.searchParams?.has("frequency")
        ? this.props?.searchParams?.get("frequency")?.split(",") || [
            "M",
            "Q",
            "NA",
            "N"
          ]
        : ["M", "Q", "NA", "N"],
      chunkSize: 50,
      currPage: 0,
      tagList: [],
      searchText: "",
      typing: false,
      typingTimeout: 0,
      showReqeustModal: false,
      requestId: ""
      //currentYear: years[0].name
    };
  }

  // Chunk Size for number of table data displayed in each page during pagination
  //chunkSize = 5;
  // Selected pagination value
  //currPage = 0;

  //Get Tag Data

  getQRMPlist = (downloadtype: boolean, notification: boolean) => {
    let quarter = this.state.selectedQuarter;
    let download = downloadtype;

    let status =
      this.state.selectedQRMP.length === 0
        ? ["M", "Q", "NA", "N"]
        : this.state.selectedQRMP;
    //we have to send all the status by default to load the complete list of the GSTIN return status as per API setup

    console.log(
      `🚀 ~ file: Index.tsx:144 ~ QRMP ~ this.state.selectedQRMP~`,
      this.state.selectedQRMP
    );

    let tags = this.state.selectedTag; //we have to send blank in case we do not want to filter GSTIN on the basis of tags
    let searchText = this.state.searchText;
    let skip = this.state.chunkSize * this.state.currPage;
    let limit = this.state.chunkSize;

    this.setState({
      loading: true,
      exportIcon: downloadtype ? "loading" : "outline/download"
    });

    downloadtype === true //logic for download file option
      ? agent.QRMP.getAll(
          quarter,
          download,
          status,
          tags,
          searchText,
          skip,
          limit
        )
          .then((response: any) => {
            downloadFile(
              response,
              `GST QRMP Status-${this.state.selectedQuarter}.xlsx`
            );
            (this.props as any).onNotify(
              "Download",
              "Report has been successfully exported in excel",
              "success"
            );
            this.setState({ loading: false, exportIcon: "outline/download" });
          })
          .catch((err: any) => {
            this.setState({ loading: false, exportIcon: "outline/download" });
            (this.props as any).onNotify(
              "Error",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          })
      : agent.QRMP.getAll(
          quarter,
          download,
          status,
          tags,
          searchText,
          skip,
          limit
        )
          .then((response: any) => {
            if (notification === true) {
              (this.props as any).onNotify(
                "QRMP Status",
                "QRMP status of the selected quarter is listed successfully",
                "success"
              );
            }
            this.setState({
              displayQRMPDetails: response.gstin,
              QRMPDetails: response.gstin,
              loading: false,
              totalRecords: response.count,
              tableHeadingReturnPeriod: quarter,
              allGSTIN: response.gstin.map((list: any) => list.gstin)
            });
          })
          .catch((err: any) => {
            this.setState({ loading: false });
            (this.props as any).onNotify(
              "Could not load QRMP List",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          });
  };

  handleValue = (value: any) => {
    this.setState({ selectedQuarter: value });
  };

  tagListing = () => {
    agent.Tag.getAll()
      .then((response: any) => {
        let finalTagList = response.tags.filter(
          (tag: any) => tag.active === true
        );
        //.map((tag: any) => tag.name);
        this.setState({ tagList: finalTagList });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  //onMount Load data
  componentDidMount() {
    this.tagListing();
    this.getQRMPlist(false, false);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.chunkSize !== this.state.chunkSize) {
      setTimeout(() => {
        this.getQRMPlist(false, false);
      }, 100);
    }
    /* if (
      prevState.modalOpen !== this.state.modalOpen &&
      this.state.modalOpen === false
    ) {
      setTimeout(() => {
        this.getQRMPlist(false,false);
      }, 100);
    } */
  }

  // on page change, load new data from database
  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.getQRMPlist(false, false);
    });
  };

  /* handlePageClick = (data: any) => {
    let selectedPage = data.selected; //trying to set page using a new variable
    //this.state.currPage = data.selected; //Incorrect way of doing thing. Have to correct it at the time of correcting the logic for fetching number of records
    this.setState({
      currPage: selectedPage, //current page is not setting up correctly. Have to research more
      displayQRMPDetails: this.state.QRMPDetails.slice(
        selectedPage * this.state.chunkSize,
        selectedPage * this.state.chunkSize + this.state.chunkSize
      )
    });
    this.getQRMPlist(false, false);
    ;
  };
 */
  editGSTName = (gstid: string) => {
    this.setState({
      modalOpen: true,
      selectedTagId: gstid
    });
  };

  handleModalChange = (value: boolean) => {
    this.setState({
      modalOpen: value
    });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
    this.getQRMPlist(false, false);
  };

  handleValueStatus = (values: string[]) => {
    const selectedQRMP = values.map(value =>
      value === "Monthly"
        ? "M"
        : value === "Quarterly"
        ? "Q"
        : value === "Not Checked"
        ? "NA"
        : value === "Not Opted"
        ? "N"
        : value
    );

    this.setState({ selectedQRMP });
  };

  handleValueTag = (value: any) => {
    let tagList = this.state.tagList;
    let selectedTags: any = value;
    let selectedTagObject: any = tagList.filter((tag: any) =>
      selectedTags.includes(tag.name)
    );
    let selectedTagId: any = selectedTagObject.map((tag: any) => tag._id);
    this.setState({ selectedTag: selectedTagId });
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: ev.target.value
    });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      typing: false,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getQRMPlist(false, false);
      }, 700)
    });
  };

  QRMPUpdate = (
    selectedGSTIN: any,
    selectedquarter: string,
    notification: boolean,
    realtime: boolean
  ) => {
    this.setState({ logging: true });
    let GSTIN: any = selectedGSTIN;
    let quarter: string = selectedquarter;
    agent.QRMP.updateQRMP({
      quarter,
      GSTIN
    })
      .then((response: any) => {
        if (realtime === true) {
          this.setState({ logging: false }, () => {
            this.getQRMPlist(false, true);
          });
        } else {
          this.setState({ logging: false });
          this.setState({ requestId: response.importRequest }, () => {
            this.requestProgress(this.state.requestId);
          });
          /* (this.props as any).onNotify(
            "QRMP Update Request",
            "We have taken your request and will send you an email once the QRMP status is updated for all the GSTIN.",
            "success"
          ); */
        }
      })
      .catch((err: any) => {
        //this.setState({ logging: false });
        (this.props as any).onNotify(
          "Could not get GSTIN Data",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  lastDayofQRMP = () => {
    let monthString: string = this.state.tableHeadingReturnPeriod.slice(0, 3);
    let month: number =
      monthString === "Jan"
        ? 1
        : monthString === "Feb"
        ? 2
        : monthString === "Mar"
        ? 3
        : monthString === "Apr"
        ? 4
        : monthString === "May"
        ? 5
        : monthString === "Jun"
        ? 6
        : monthString === "Jul"
        ? 7
        : monthString === "Aug"
        ? 8
        : monthString === "Sep"
        ? 9
        : monthString === "Oct"
        ? 10
        : monthString === "Nov"
        ? 11
        : monthString === "Dec"
        ? 12
        : 0;

    let year: number = Number(
      "20".concat(this.state.tableHeadingReturnPeriod.slice(4, 6))
    );
    let date: number = 1;
    return new Date(year, month, date).toISOString();
  };

  requestModalSetOpen = (open: boolean) => {
    this.setState({
      showReqeustModal: open
    });
  };

  requestProgress = (requestId: string) => {
    this.setState({ id: requestId });
    this.requestModalSetOpen(true);
  };

  /* extractPreference = (filingFreq: any[], quarter: string) => {
    const prefArray = filingFreq.filter(item => item.quarter === quarter && item.year === this.state.currentYear);
    return prefArray.length === 0 ? "Unavailable" : prefArray[0].preference === "Q" ? "Quarterly" : "Monthly";
  }; */

  render() {
    TagManager.dataLayer(tagManagerArgs);
    //console.log("lastDayofQRMP", this.lastDayofQRMP());
    return (
      <Dashboard>
        <RequestProgress
          type="QRMP"
          onLoad={() => {
            this.getQRMPlist(false, false);
          }}
          requestModalSetOpen={this.requestModalSetOpen}
          state={this.state}
        />
        <div className="gsts">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              QRMP Status
            </h1>
          </div>
          <div className="grid grid-cols-3 grid-flow-col gap-x-8 gap-y-4 px-4 sm:px-6 md:px-8 pt-8 pb-2">
            <div>
              <SearchList
                heading="Select QRMP Period"
                list={quarters()}
                handleValue={this.handleValue}
                defaultValue={this.state.selectedQuarter}
              />
            </div>
            <div className="space-y-4">
              <div>
                <MultiSelect
                  heading="Select Frequency"
                  list={qrmpOptions.map(qrmp => qrmp.name)}
                  handleValue={this.handleValueStatus}
                  defaultValue={this.state.selectedQRMP.map(
                    item => qrmpOptions.find(qrmp => qrmp.value === item)?.name
                  )}
                  placeholder="Showing All Filing Frequency"
                />
              </div>
              <div>
                <MultiSelect
                  heading="Select Tags"
                  list={this.state.tagList.map((tag: any) => tag.name)}
                  handleValue={this.handleValueTag}
                  defaultValue={[]}
                  placeholder="Showing All Tags"
                />
              </div>
            </div>
          </div>
          <div className="space-x-4 max-w-7xl mx-auto px-4 py-3 sm:px-6 md:px-8">
            <button
              type="button"
              disabled={this.state.loading}
              onClick={() => this.getQRMPlist(false, true)}
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Icon
                name="outline/list"
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              List QRMP Status
            </button>
          </div>

          {/*           <div>
            <label htmlFor="combobox" className="block text-sm font-medium text-gray-700">
              Assigned to
            </label>
            <div className="relative mt-1">
              <input id="combobox" type="text" className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" role="combobox" aria-controls="options" aria-expanded="false" />
              <button type="button" className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                // <!-- Heroicon name: solid/selector -->
                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>

              <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" id="options" role="listbox">
                <!--
                  Combobox option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

                   Active: "text-white bg-indigo-600", Not Active: "text-gray-900"
                  -->
                 <li className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900" id="option-0">
                  <!-- Selected: "font-semibold" -->
                  <span className="block truncate">Leslie Alexander</span>

                  <!--
                    Checkmark, only display for selected option.

                    Active: "text-white", Not Active: "text-indigo-600"
                   -->
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                    <!-- Heroicon name: solid/check -->
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </li>

                <!-- More items... -->
              </ul>
            </div>
          </div> */}
          {/* <EditNameModal gstId={this.state.selectedTagId} openModal={this.state.modalOpen} handleModalChange={this.handleModalChange} /> */}
          {!this.state.loading && this.state.displayQRMPDetails ? (
            this.state.totalRecords > 0 || this.state.searchText.length > 0 ? (
              <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
                <div className="py-12">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="text-xl	text-center whitespace-normal pt-0 pb-3 text-decoration-line: underline font-medium	text-gray-900">
                        QRMP status of for the period{" "}
                        {this.state.tableHeadingReturnPeriod}
                      </div>
                      <div>
                        <div className="grid-cols-3 basis-2 md:px-8 py-2 grid">
                          <div className="justify-self-start">
                            <button
                              type="button"
                              disabled={
                                this.state.loading || this.state.logging
                              }
                              onClick={() =>
                                this.QRMPUpdate(
                                  [],
                                  this.state.selectedQuarter,
                                  true,
                                  false
                                )
                              }
                              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                              <Icon
                                name="outline/update"
                                className="-ml-1 mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              Update QRMP Status
                            </button>
                          </div>
                          <div>
                            <div className="justify-self-stretch items-center">
                              <input
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Search by Legal Name, Trade Name or GSTIN"
                                value={this.state.searchText}
                                onChange={(ev: any) => {
                                  this.handleSearchTextChange(ev);
                                }}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="justify-self-end">
                            <button
                              type="button"
                              disabled={this.state.loading}
                              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                              onClick={() => {
                                this.getQRMPlist(true, true);
                              }}
                            >
                              <Icon
                                name={this.state.exportIcon}
                                className="-ml-1 mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              Export as XLSX
                            </button>
                          </div>
                        </div>
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider divide-y divide-gray-200"
                                  >
                                    Legal Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Trade Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    GSTIN
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Filling Frequency
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Last Checked
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Check Status
                                  </th>
                                  {/* <th scope="col" className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Cancellation Date
                                </th> */}
                                  {/* <th scope="col" className="w-4/12 px-4 py-3 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Filing Frequency
                                  <table>
                                    <thead>
                                      <tr>
                                        <th id="Q1" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q1 <br /> Apr-Jun
                                        </th>
                                        <th id="Q2" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q2 <br /> Jul-Sep
                                        </th>
                                        <th id="Q3" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q3
                                          <br /> Oct-Dec
                                        </th>
                                        <th id="Q4" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q4
                                          <br /> Jan-Mar
                                        </th>
                                      </tr>
                                    </thead>
                                  </table>
                                </th> */}
                                </tr>
                              </thead>
                              {this.state.totalRecords === 0 ? (
                                <div className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                  No record found matching your search criteria
                                </div>
                              ) : (
                                <tbody>
                                  {this.state.displayQRMPDetails.map(
                                    (tag: any) => (
                                      <tr key={tag._id} className="bg-white">
                                        <td
                                          key={tag.id}
                                          className="w-2/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                        >
                                          {tag.legalName}
                                          {/* <button type="button" onClick={() => this.editGSTName(tag._id)} className="ml-3 items-center px-1 py-1 border border-transparent text-xs rounded text-black bg-white hover:bg-gray-200 focus:outline-none">
                                      <Icon name="edit" className="mx-auto flex-shrink-0 h-4 w-4" />
                                    </button> */}
                                        </td>
                                        <td
                                          key={tag.id}
                                          className="w-2/10 px-6 py-3 whitespace-wrap text-sm text-gray-500"
                                        >
                                          {tag.tradeName}
                                        </td>
                                        <td
                                          key={tag.id}
                                          className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                        >
                                          {tag.gstin}
                                        </td>
                                        <td
                                          key={tag.id}
                                          className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                        >
                                          {tag.filingFrequency === "Q"
                                            ? "Quarterly"
                                            : tag.filingFrequency === "M"
                                            ? "Monthly"
                                            : tag.filingFrequency === "N"
                                            ? "Not Opted"
                                            : tag.filingFrequency === "NA"
                                            ? "Not Checked"
                                            : "-"}
                                        </td>
                                        <td
                                          key={tag.id}
                                          className="w-1/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                        >
                                          {tag.lastChecked
                                            ? new Date(
                                                tag.lastChecked
                                              ).toLocaleDateString() +
                                              " - " +
                                              new Date(
                                                tag.lastChecked
                                              ).toLocaleTimeString("en-US", {
                                                hour: "2-digit",
                                                minute: "2-digit"
                                              })
                                            : "-"}
                                        </td>
                                        <td
                                          key={tag.id}
                                          className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                        >
                                          {tag.filingFrequency === "N" ||
                                          tag.filingFrequency === "NA" ||
                                          (tag.filingFrequency === "M" &&
                                            tag.lastChecked <
                                              this.lastDayofQRMP()) ||
                                          (tag.filingFrequency === "Q" &&
                                            tag.lastChecked <
                                              this.lastDayofQRMP()) ? (
                                            <button
                                              className="cursor-pointer"
                                              disabled={this.state.logging}
                                              onClick={() => {
                                                this.QRMPUpdate(
                                                  [tag.gstin],
                                                  this.state
                                                    .tableHeadingReturnPeriod,
                                                  false,
                                                  true
                                                );
                                              }}
                                            >
                                              {this.state.logging ? (
                                                <Icon
                                                  name="loading"
                                                  className="mx-auto h-4 w-4"
                                                />
                                              ) : (
                                                "Check"
                                              )}
                                            </button>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        {/* <td key={tag.id} className="w-1/10 px-6 py-3 whitespace-wrap text-sm text-gray-500">
                                    {tag.cxdt ? new Date(tag.cxdt).toLocaleDateString() : "-"}
                                  </td> */}
                                        {/* <td>
                                    <table>
                                      <tbody>
                                        <tr className="flex w-4/10 pl-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q1")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q2")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q3")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q4")}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td> */}
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                <Icon
                  name="outline/document-add"
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  strokeWidth="1"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No GST Found{" "}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by adding a new GSTN.
                </p>
                <div className="mt-6">
                  <Link to={"/tags/add"}>
                    {/* <Link to={"/tags/add"}> */}
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      ADD GSTN
                    </button>
                  </Link>
                </div>
              </div>
            )
          ) : (
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                GST Number
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Registered Date
                              </th>
                              <th
                                scope="col"
                                className="w-4/12 px-4 py-3 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Filing Frequency
                                <table>
                                  <thead>
                                    <tr>
                                      <th
                                        id="Q1"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q1 <br /> Apr-Jun
                                      </th>
                                      <th
                                        id="Q2"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q2 <br /> Jul-Sep
                                      </th>
                                      <th
                                        id="Q3"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q3
                                        <br /> Oct-Dec
                                      </th>
                                      <th
                                        id="Q4"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q4
                                        <br /> Jan-Mar
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {[...Array(4)].map((e, i) => (
                              <tr key={i} className="bg-white">
                                {[...Array(4)].map((e, i) => (
                                  <td
                                    key={i}
                                    className="w-full px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                  >
                                    <Skeleton />
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.totalRecords > 0 ? (
            <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {this.state.currPage * this.state.chunkSize + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {(this.state.currPage + 1) * this.state.chunkSize >
                      this.state.totalRecords
                        ? this.state.totalRecords
                        : (this.state.currPage + 1) * this.state.chunkSize}
                    </span>{" "}
                    of{" "}
                    <span className="font-medium">
                      {this.state.totalRecords}
                    </span>{" "}
                    results
                  </p>
                </div>
                <div className="text-sm text-gray-700 flex items-center ml-3 mr-3 gap-2">
                  <div>Items per page</div>
                  <div className="data-dropdown-placement={top}">
                    <SelectMenu
                      items={itemsPerPage}
                      selected={{ name: this.state.chunkSize }}
                      onChange={this.handleItemPerPage}
                    />
                  </div>
                </div>
              </div>
              <div id="pagination" className="text-sm text-gray-500 my-2">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(
                    this.state.totalRecords / this.state.chunkSize
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(QRMP) as ComponentType;
