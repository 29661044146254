const months = [
  { name: "Apr" },
  { name: "May" },
  { name: "Jun" },
  { name: "Jul" },
  { name: "Aug" },
  { name: "Sep" },
  { name: "Oct" },
  { name: "Nov" },
  { name: "Dec" },
  { name: "Jan" },
  { name: "Feb" },
  { name: "Mar" }
];

export default months;

export const calendarMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
