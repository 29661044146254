import React, { ComponentType } from "react";
//Redux Notifications
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Dashboard from "../../components/Dashboard";
import Icon from "../../components/Icon";
import routes from "../../constants/routes";
import { ADD_GST, ADD_NOTIFICATION } from "../../store/types";
import TagManager from "react-gtm-module";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "tagadd"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addGst: (gst: any) =>
    dispatch({
      type: ADD_GST,
      payload: { gst }
    }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & WithRouterProps;

//Main Classs
class AddTag extends React.Component<PropsFromRedux> {
  state: { Tag: string; Description: any; logging: boolean };

  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      Tag: "",
      Description: "",
      logging: false
    };
  }

  addTag = () => {
    let isValid = true;
    //let isValid = validGSTIN(this.state.GSTN);
    if (isValid) {
      this.setState({ logging: true });
      agent.Tag.addTag({
        name: this.state.Tag.trim(),
        description: this.state.Description.trim()
      })
        .then((response: any) => {
          this.setState({ logging: false });
          this.props.navigate?.("/tags"); //RP Pending Work - Have to change the hardcoded URL
          //(this.props as any).addTag(response.addTag);
          (this.props as any).onNotify(
            "Added Tag",
            "Successfully added new Tag.",
            "success"
          );
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).onNotify(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      (this.props as any).onNotify(
        "Error",
        "We are facing some error. Please contact support.",
        "danger"
      );
    }
  };

  /* addMultipleGst = () => {
    let MultipleArrayGSTIN: string[] = this.state.MultipleGSTIN.split("\n");
    let isValid = true; //validGSTIN(this.state.GSTN); RP Pending Work - Have to validate the GSTIN in the array before processing
    if (isValid) {
      this.setState({ logging: true });
      agent.Gst.addmultipleGst(MultipleArrayGSTIN, [])
        .then((response: any) => {
          this.setState({ logging: false });
          this.props.history.push(routes.AgencyClients);
          //(this.props as any).addGst(response.gst);
          //(this.props as any).onNotify("Added GSTIN", "Successfully added new GSTIN.", "success");
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).onNotify("Could not get GSTIN Data", err?.response?.data?.message || err?.message || err, "danger");
        });
    } else {
      (this.props as any).onNotify("Incorrect GSTIN", "Please enter a valid GSTIN to proceed.", "danger");
    }
  };
 */

  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: ev.target.value
    });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 gstadd">
          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Add Tag
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Tag are associated to GSTIN. You can use tags to filter
                    GSTIN.
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form>
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="company_website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Tag
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="company_website"
                              value={this.state.Tag}
                              onChange={this.updateState("Tag")}
                              id="company_website"
                              className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                              placeholder="Top Clients"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="about"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <textarea
                            id="about"
                            name="about"
                            rows={3}
                            className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                            placeholder="Top 20 percent client of the firm"
                            value={this.state.Description}
                            onChange={this.updateState("Description")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="space-x-4 px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="button"
                        onClick={this.addTag}
                        disabled={this.state.logging}
                        className={
                          this.state.logging
                            ? "inline-flex disabled justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                            : "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                        }
                      >
                        {this.state.logging ? <Icon name="loading" /> : null}
                        Save Tag
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(AddTag) as ComponentType;
