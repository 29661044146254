import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import agent from "./agent";
import Notification from "./components/Notification";
// Page Imports
import GSTs from "./pages/GSTs/Index";
import AddGst from "./pages/GSTs/Add";
import Tags from "./pages/Tags/Index";
import AddTag from "./pages/Tags/Add";
import QRMP from "./pages/QRMP/Index";
import Rstatus from "./pages/Rstatus/Index";
import SignUpCompleted from "./pages/Verify/SignUpCompleted";
import Subscriptions from "./pages/Subscriptions/Index";
// import PaymentResponse from "./pages/Subscriptions/paymentresponse";
import PaymentResponsePage from "./pages/Subscriptions/PaymentResponsePage";
// Sales Transactions Import
//import SalesSummary from "./pages/Sales/Summary";
//import SalesImport from "./pages/Sales/Import/Index";
//import SalesTransactions from "./pages/Sales/Transactions";
// Purchase Transactions Import
//import PurchaseSummary from "./pages/Purchases/Summary";
//import PurchaseImport from "./pages/Purchases/Import/Index";
//import PurchaseTransactions from "./pages/Purchases/Transactions";
// Returns
//import IFFImport from "./pages/Returns/IFF";
//import GSTR1Returns from "./pages/Returns/GSTR1";
//import GSTR3BReturns from "./pages/Returns/GSTR3B";
//import GSTR2ABTransactions from "./pages/Returns/GSTR2AB/Transactions";
//import GSTR2ABSummary from "./pages/Returns/GSTR2AB/Summary";
//import GSTR2ABImport from "./pages/Returns/GSTR2AB/Import";
//import ITCClaim from "./pages/Returns/ITCClaim";
// Login Import
import Login from "./pages/Login";
import Settings from "./pages/Settings/Index";
// Sign Up Imports
import Signup from "./pages/Signup";
import SignupOTP from "./pages/Verify/SignupOTP";
import SignupToken from "./pages/Verify/SignupToken";
// Forgot Password Imports
import ForgetPassword from "./pages/ForgetPassword";
import ResetOTP from "./pages/Verify/ResetOTP";
import ResetToken from "./pages/Verify/ResetToken";
// Connect to redux
import { TOKEN_PRESENT } from "./store/types";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "./store";
import NotFound from "./pages/NotFound";
import Maintenance from "./components/Maintenance";

const mapStateToProps = (state: RootState) => ({
  ...state.user,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  onTokenPresent: (token: string) =>
    dispatch({ type: TOKEN_PRESENT, payload: { token } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class App extends React.Component<PropsFromRedux> {
  // Getting Cookies and Set Token from Cookies

  state: {
    loading: boolean;
  };
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false
    };
  }

  getCookie = (cname: string) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  async UNSAFE_componentWillMount() {
    this.setState({ loading: true });
    if (this.getCookie("token")) {
      agent.setToken(this.getCookie("token"));
      await this.props.onTokenPresent("PRESENT");
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
  }

  PrivateRoute = ({ children }: { children: JSX.Element }) => {
    return this.props.isAuthenticated ? children : <Navigate to="/" />;
  };

  PublicRoute = ({ children }: { children: JSX.Element }) => {
    return this.props.isAuthenticated ? <Navigate to="/gsts" /> : children;
  };

  render() {
    return this.props.showMaintenance ? (
      <Maintenance />
    ) : (
      <>
        <Notification />
        {!this.state.loading ? (
          <BrowserRouter>
            <Routes>
              {/* SignUp */}
              <Route
                path="/signup"
                element={
                  <this.PublicRoute>
                    <Signup />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/signup/otp/:id"
                element={
                  <this.PublicRoute>
                    <SignupOTP />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/signup/token/:token"
                element={
                  <this.PublicRoute>
                    <SignupToken />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/signup/completed"
                element={
                  <this.PublicRoute>
                    <SignUpCompleted />
                  </this.PublicRoute>
                }
              />
              {/* Forgot Password */}
              <Route
                path="/forgetPassword"
                element={
                  <this.PublicRoute>
                    <ForgetPassword />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/reset/otp/:id"
                element={
                  <this.PublicRoute>
                    <ResetOTP />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/reset/token/:token"
                element={
                  <this.PublicRoute>
                    <ResetToken />
                  </this.PublicRoute>
                }
              />
              {/* Login */}

              <Route
                path="/"
                element={
                  <this.PublicRoute>
                    <Login />
                  </this.PublicRoute>
                }
              />
              {/* GST Page */}
              <Route
                path="/gsts"
                element={
                  <this.PrivateRoute>
                    <GSTs />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/gsts/add"
                element={
                  <this.PrivateRoute>
                    <AddGst />
                  </this.PrivateRoute>
                }
              />
              {/* Tags Page */}
              <Route
                path="/tags"
                element={
                  <this.PrivateRoute>
                    <Tags />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/tags/add"
                element={
                  <this.PrivateRoute>
                    <AddTag />
                  </this.PrivateRoute>
                }
              />
              {/* QRMP Page */}
              <Route
                path="/qrmp"
                element={
                  <this.PrivateRoute>
                    <QRMP />
                  </this.PrivateRoute>
                }
              />
              {/* Return Status */}
              <Route
                path="/rstatus"
                element={
                  <this.PrivateRoute>
                    <Rstatus />
                  </this.PrivateRoute>
                }
              />
              {/* Settings */}
              <Route
                path="/settings"
                element={
                  <this.PrivateRoute>
                    <Settings />
                  </this.PrivateRoute>
                }
              />
              {/* Subscriptions */}
              <Route
                path="/subscriptions"
                element={
                  <this.PrivateRoute>
                    <Subscriptions />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/paymentresponse"
                element={
                  <this.PrivateRoute>
                    <PaymentResponsePage />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="*"
                element={
                  <NotFound loggedIn={(this.props as any).isAuthenticated} />
                }
              />
              {/* Sales Pages */}
              {/* <this.PrivateRoute exact path="/:userInfo/sales/summary" component={SalesSummary} /> */}
              {/* <this.PrivateRoute exact path="/:userInfo/sales/import" component={SalesImport} /> */}
              {/* <this.PrivateRoute exact path="/:userInfo/sales/transactions" component={SalesTransactions} /> */}
              {/* <this.PrivateRoute
                exact
                path='/sales/manual'
                component={SalesTransactions}
              /> */}
              {/* Purchase Pages */}
              {/* <this.PrivateRoute exact path="/:userInfo/purchase/summary" component={PurchaseSummary} />*/}
              {/* <this.PrivateRoute exact path="/:userInfo/purchase/import" component={PurchaseImport} />*/}
              {/* <this.PrivateRoute exact path="/:userInfo/purchase/transactions" component={PurchaseTransactions} />*/}
              {/* <this.PrivateRoute
                exact
                path='/purchase/manual'
                component={PurchaseTransactions}
              /> */}
              {/* Returns */}
              {/* <this.PrivateRoute exact path="/:userInfo/returns/iff" component={IFFImport} /> */}
              {/* <this.PrivateRoute exact path="/:userInfo/returns/gstr1" component={GSTR1Returns} /> */}
              {/* <this.PrivateRoute exact path="/:userInfo/returns/gstr2/import" component={GSTR2ABImport} /> */}
              {/* <this.PrivateRoute exact path="/:userInfo/returns/gstr2/transactions" component={GSTR2ABTransactions} /> */}
              {/* <this.PrivateRoute exact path="/:userInfo/returns/gstr2/summary" component={GSTR2ABSummary} /> */}
              {/* <this.PrivateRoute exact path="/:userInfo/returns/itcClaim" component={ITCClaim} /> */}
              {/* <this.PrivateRoute exact path="/:userInfo/returns/gstr3b" component={GSTR3BReturns} /> */}
              {/* Insights and settings */}
              {/* <this.PrivateRoute exact path="/insights" component={Settings} /> */}
            </Routes>
          </BrowserRouter>
        ) : null}
      </>
    );
  }
}

export default connector(App);
