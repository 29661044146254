import { Component, ComponentType } from "react";
import agent from "../../agent";
import Skeleton from "react-loading-skeleton";
import { ConnectedProps, connect } from "react-redux";
import { ADD_NOTIFICATION } from "../../store/types";
import { AppDispatch, RootState } from "../../store";
import { WithRouterProps, withRouter } from "../../helpers/withRouter";
import Dashboard from "../../components/Dashboard";
import { compose } from "redux";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Payment Response Page"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addNotification: (
    title: string,
    message: string,
    type: "success" | "danger"
  ) => dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux & WithRouterProps> & {
  addNotification?: (
    title: string,
    message: string,
    type: "success" | "danger"
  ) => void;
};

type PaymentStatus = "TXN_SUCCESS" | "TXN_FAILURE" | "";

interface State {
  loading: boolean;
  paymentStatus: PaymentStatus;
  paymentFailureReason: string | undefined;
  redirectTimeout: NodeJS.Timeout | null;
}

class PaymentResponsePage extends Component<Props, State> {
  state: State = {
    loading: false,
    paymentStatus: "",
    paymentFailureReason: "",
    redirectTimeout: null
  };

  orderId = this.props.searchParams?.get("orderid");

  getPaymentStatus = () => {
    if (!this.orderId) return this.props.navigate?.(`/subscriptions`);

    this.setState({ loading: true });
    agent.Payment.getPaymentStatus(this.orderId)
      .then((res: { order: { status: PaymentStatus; respmsg: string }[] }) => {
        if (res.order.length === 0) {
          this.setState({ loading: false });
          this.props.addNotification?.(
            "Could not load payment status",
            "Please try again later",
            "danger"
          );
          return;
        }

        const paymentStatus = res.order[0].status;
        const paymentFailureReason = res.order[0].respmsg;

        this.setState({
          loading: false,
          paymentStatus,
          paymentFailureReason
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Could not load payment status",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  componentDidMount() {
    this.getPaymentStatus();
  }

  componentWillUnmount(): void {
    if (this.state.redirectTimeout) {
      clearTimeout(this.state.redirectTimeout);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.paymentStatus !== prevState.paymentStatus &&
      this.state.paymentStatus
    ) {
      this.setState({
        redirectTimeout: setTimeout(() => {
          this.state.paymentStatus === "TXN_SUCCESS"
            ? this.props.navigate?.(`/`)
            : this.props.navigate?.(`/subscriptions`);
        }, 5000)
      });
    }
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <Dashboard>
        <div className="min-h-[75vh] grid gap-6 place-content-center">
          {this.state.loading ? (
            <div className="text-center">
              <Skeleton className="h-6 w-40" />
              <Skeleton className="h-6 w-96" />
            </div>
          ) : (
            <div className="text-center">
              {this.orderId ? (
                this.state.paymentStatus === "TXN_SUCCESS" ? (
                  <div>
                    <h3 className="capitalize text-2xl">Payment Successful</h3>
                    <p>
                      Thanks for making the payment. Your account has been
                      successfully updated.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h3 className="capitalize text-2xl">Payment Failed</h3>
                    <p>
                      {this.state.paymentFailureReason ||
                        "Your payment could not be processed. Please try again."}
                    </p>
                  </div>
                )
              ) : (
                <div>
                  <h3 className="capitalize text-2xl">No payment found</h3>
                  <p>Please return to the subscriptions page and try again.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default compose<ComponentType<Props>>(
  connector,
  withRouter
)(PaymentResponsePage);
