import { PlusIcon } from "@heroicons/react/24/solid";
import React, { ComponentType } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
// Pagination
import ReactPaginate from "react-paginate";
import { connect, ConnectedProps } from "react-redux";
// Routing imports
import { Link } from "react-router-dom";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
import SelectMenu from "../../components/SelectMenu";
import itemsPerPage from "../../constants/itemsPerPage";
//import years from "../../constants/years";
// Redux Notify
import { ADD_NOTIFICATION } from "../../store/types";
import "../style.css";
import TagManager from "react-gtm-module";
//import { string } from "prop-types";
//import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
//import { Combobox } from "@headlessui/react";
import SearchList from "../QRMP/searchList";
import MultiSelect from "../../components/MultiSelect";
//import quarters from "../../helpers/quarters";
import {
  monthTillLastMonth,
  yearTillLastYear,
  monthTillLastMonthIFF,
  quarterTillLastQuarter
} from "../../helpers/periods";
import returntypes from "../../constants/returntypes";
import { downloadFile } from "../../helpers/downloadFile";
import RequestProgress from "../../components/RequestProgress";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";
import { calendarMonths } from "../../constants/months";
import { getQuarterFromMonthOrQuarter } from "../../helpers/taskPeriod";
import MessageModal from "../../components/MessageModal";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "GSTReturnStatus"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping

const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & WithRouterProps;
class Rstatus extends React.Component<PropsFromRedux> {
  state: {
    loading: boolean;
    downloading: boolean;
    GSTReturnDetails: any;
    totalRecords: number;
    displayGSTReturnDetails: any;
    allGSTIN: [];
    selectedTagId: string;
    modalOpen: boolean;
    selectedReturnType: string;
    selectedPeriod: string;
    exportIcon: string;
    tableHeadingReturnType: string;
    tableHeadingReturnPeriod: string;
    selectedStatus: string[];
    selectedTag: string[];
    chunkSize: number;
    currPage: any;
    tagList: [];
    searchText: string;
    typing: boolean;
    typingTimeout: number;
    showReqeustModal: boolean;
    //requestId: boolean | undefined;
    requestId: string;
    showQrmpModal: boolean;
    clientsWithoutQrmp: number;

    //currentYear: any;
  };

  constructor(props: PropsFromRedux) {
    super(props);

    this.state = {
      loading: false,
      downloading: false,
      GSTReturnDetails: [],
      totalRecords: 0,
      displayGSTReturnDetails: [],
      allGSTIN: [],
      selectedTagId: "",
      modalOpen: false,
      selectedReturnType: returntypes[0],
      selectedPeriod: quarterTillLastQuarter()[0],
      tableHeadingReturnType: returntypes[0],
      tableHeadingReturnPeriod: quarterTillLastQuarter()[0],
      exportIcon: "outline/download",
      selectedStatus: ["filed", "not filed", "not checked"],
      selectedTag: [""],
      chunkSize: 50,
      currPage: 0,
      tagList: [],
      searchText: "",
      typing: false,
      typingTimeout: 0,
      showReqeustModal: false,
      requestId: "",
      showQrmpModal: false,
      clientsWithoutQrmp: 0
      //currentYear: years[0].name
    };
  }

  // Chunk Size for number of table data displayed in each page during pagination
  //chunkSize = this.state.itemPerPage;
  // Selected pagination value
  //currPage = 0;

  //Get Return Status

  getReturnStatus = (downloadtype: boolean, notification: boolean) => {
    let period: string = this.state.selectedPeriod;
    let task: string =
      this.state.selectedReturnType === "GSTR-3B Quarterly"
        ? "GSTR3B"
        : this.state.selectedReturnType === "GSTR-3B Monthly"
        ? "GSTR3B"
        : this.state.selectedReturnType === "GSTR-1 Quarterly"
        ? "GSTR1"
        : this.state.selectedReturnType === "GSTR-1 Monthly"
        ? "GSTR1"
        : this.state.selectedReturnType === "GSTR-9"
        ? "GSTR9"
        : this.state.selectedReturnType === "GSTR-4"
        ? "GSTR4"
        : this.state.selectedReturnType === "CMP-08"
        ? "CMP08"
        : this.state.selectedReturnType;
    let download: boolean = downloadtype;
    let status: string[] =
      this.state.selectedStatus.length === 0
        ? ["filed", "not filed", "not checked"]
        : this.state.selectedStatus; //we have to send all the status by default to loan the complete list of the GSTIN return status as per API setup
    let tags: string[] = this.state.selectedTag; //we have to send blank in case we do not want to filter GSTIN on the basis of tags
    let searchText = this.state.searchText;
    let skip: number = this.state.chunkSize * this.state.currPage;
    let limit: number = this.state.chunkSize;

    if (downloadtype === true) {
      this.setState({ exportIcon: "loading", downloading: true });
      //logic for download file option
      agent.Rstatus.getAll(
        period,
        task,
        download,
        status,
        tags,
        searchText,
        skip,
        limit
      )
        .then((response: any) => {
          downloadFile(
            response,
            `GST Return Status-${this.state.selectedReturnType}-${this.state.selectedPeriod}.xlsx`
          );
          (this.props as any).onNotify(
            "Download",
            "Report has been successfully exported in excel",
            "success"
          );
          this.setState({
            loading: false,
            exportIcon: "outline/download",
            downloading: false
          });
        })
        .catch((err: any) => {
          this.setState({
            loading: false,
            exportIcon: "outline/download",
            downloading: false
          });
          (this.props as any).onNotify(
            "Error",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      this.setState({ loading: true });
      this.getQrmpList();

      agent.Rstatus.getAll(
        period,
        task,
        download,
        status,
        tags,
        searchText,
        skip,
        limit
      )
        .then((response: any) => {
          if (notification === true) {
            (this.props as any).onNotify(
              "Return Status",
              "Return status of the selected period is listed successfully",
              "success"
            );
          }
          this.setState({
            displayGSTReturnDetails: response.returnStatus,
            GSTReturnDetails: response.returnStatus,
            loading: false,
            totalRecords: response.count,
            allGSTIN: response.returnStatus.map((list: any) => list.gstin),
            tableHeadingReturnPeriod: period,
            tableHeadingReturnType: this.state.selectedReturnType
          });
        })
        .catch((error: any) => {
          this.setState({ loading: false });
          (this.props as any).onNotify(
            "Could not load GSTIN List",
            error?.response?.data?.message || error?.message || error,
            "danger"
          );
        });
    }
  };

  getQrmpList = () => {
    const status = ["NA", "N"];
    const tags: string[] = [];
    const searchText = "";
    const skip = 0;
    const limit = 100000;

    const quarter = getQuarterFromMonthOrQuarter(this.state.selectedPeriod);

    console.log(`🚀 ~ file: Index.tsx:257 ~ Rstatus ~ quarter~`, quarter);

    agent.QRMP.getAll(quarter, false, status, tags, searchText, skip, limit)
      .then((res: any) => {
        res.count &&
          this.setState({ showQrmpModal: true, clientsWithoutQrmp: res.count });
      })
      .catch(err => {
        (this.props as any).onNotify(
          "Could not load QRMP List",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  openQrmpPage = () => {
    const quarter = getQuarterFromMonthOrQuarter(this.state.selectedPeriod);

    (this.props as any).navigate(`/QRMP?quarter=${quarter}&frequency=NA,N`);
  };

  handleCloseQrmpModal = () => this.setState({ showQrmpModal: false });

  rStatusUpdate = (
    selectedGSTIN: any,
    selectedPeriod: string,
    selectedReturnType: string,
    notification: boolean,
    realtime: boolean
  ) => {
    this.setState({ loading: true });
    let gstin: any = selectedGSTIN;
    let period: string = selectedPeriod;
    let task: string =
      selectedReturnType === "GSTR-3B Monthly"
        ? "GSTR3B"
        : this.state.selectedReturnType === "GSTR-3B Quarterly"
        ? "GSTR3B"
        : this.state.selectedReturnType === "GSTR-1 Monthly"
        ? "GSTR1"
        : this.state.selectedReturnType === "GSTR-1 Quarterly"
        ? "GSTR1"
        : this.state.selectedReturnType === "GSTR-9"
        ? "GSTR9"
        : this.state.selectedReturnType === "GSTR-4"
        ? "GSTR4"
        : this.state.selectedReturnType === "CMP-08"
        ? "CMP08"
        : this.state.selectedReturnType;

    agent.Rstatus.updateRstatus({ gstin, period, task })

      .then((response: any) => {
        if (realtime === true)
          this.setState({ loading: false }, () => {
            this.getReturnStatus(false, true);
          });
        else {
          this.setState({ loading: false });
          this.setState({ requestId: response.importRequest }, () => {
            this.requestProgress(this.state.requestId);
          });

          /* (this.props as any).onNotify(
            "GST Return Update Request",
            "We have taken your request and will send you an email once the GST return status is updated for all the GSTIN.",
            "success"
          ); */
        }
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not get GSTIN List",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  tagListing = () => {
    agent.Tag.getAll()
      .then((response: any) => {
        let finalTagList = response.tags.filter(
          (tag: any) => tag.active === true
        );
        //.map((tag: any) => tag.name);
        this.setState({ tagList: finalTagList });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  /* requestList = () => {
    this.setState({ loading: true });
    let skip: number = 0;
    let limit: number = 1;
    let type: string = "ReturnStatus";
    agent.Request.getAll(type, skip, limit)
      .then((response: any) => {
        this.setState({ loading: false });
        this.setState({ requestId: response[0]._id });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
      });
  };
 */
  handleValueRType = (value: any) => {
    this.setState({ selectedReturnType: value });
  };

  handleValuePeriod = (value: any) => {
    this.setState({ selectedPeriod: value });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
    this.getReturnStatus(false, false);
  };

  handleValueStatus = (value: any) => {
    this.setState({ selectedStatus: value });
  };

  handleValueTag = (value: any) => {
    let tagList = this.state.tagList;
    let selectedTags: any = value;
    let selectedTagObject: any = tagList.filter((tag: any) =>
      selectedTags.includes(tag.name)
    );
    let selectedTagId: any = selectedTagObject.map((tag: any) => tag._id);
    this.setState({ selectedTag: selectedTagId });
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.getReturnStatus(false, false);
    });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      typing: false,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getReturnStatus(false, false);
      }, 700)
    });
  };

  //onMount Load data
  componentDidMount() {
    this.getReturnStatus(false, false);
    this.tagListing();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.chunkSize !== this.state.chunkSize) {
      setTimeout(() => {
        this.getReturnStatus(false, false);
      }, 100);
    }
    /* if (
      prevState.selectedPeriod !== this.state.selectedPeriod &&
      prevState.selectedReturnType === this.state.selectedReturnType
    ) {
      this.getGSTlist(false);
    } else if (
      prevState.selectedPeriod === this.state.selectedPeriod &&
      prevState.selectedReturnType !== this.state.selectedReturnType
    ) {
      this.getGSTlist(false);
    } */
  }

  editGSTName = (gstid: string) => {
    this.setState({
      modalOpen: true,
      selectedTagId: gstid
    });
  };

  handleModalChange = (value: boolean) => {
    this.setState({
      modalOpen: value
    });
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: ev.target.value
    });
  };

  requestModalSetOpen = (open: boolean) => {
    this.setState({
      showReqeustModal: open
    });
  };

  requestProgress = (requestId: string) => {
    this.setState({ id: requestId });
    this.requestModalSetOpen(true);
  };

  /* showLoader = () => {
    
  }; */

  classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  /* extractPreference = (filingFreq: any[], quarter: string) => {
    const prefArray = filingFreq.filter(item => item.quarter === quarter && item.year === this.state.currentYear);
    return prefArray.length === 0 ? "Unavailable" : prefArray[0].preference === "Q" ? "Quarterly" : "Monthly";
  }; */

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {this.state.showQrmpModal && (
          <MessageModal
            show={this.state.showQrmpModal}
            handleConfirm={this.openQrmpPage}
            confirmText="Update QRMP"
            handleClose={this.handleCloseQrmpModal}
            closeText="Cancel"
            data={{
              title: "QRMP",
              message: `There are ${this.state.clientsWithoutQrmp} clients without QRMP status. Do you want to update QRMP status for these?`,
              type: "info"
            }}
          />
        )}

        <RequestProgress
          type="ReturnStatus"
          onLoad={() => {
            this.getReturnStatus(false, false);
          }}
          requestModalSetOpen={this.requestModalSetOpen}
          state={this.state}
        />
        <div className="gsts">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              GST Return Status
            </h1>
          </div>

          <div className="grid grid-cols-3 grid-rows-2 grid-flow-col gap-x-8 gap-y-4 px-4 sm:px-6 md:px-8 pt-8 pb-2">
            <div>
              <SearchList
                heading="Select Return Type"
                list={returntypes}
                handleValue={this.handleValueRType}
                defaultValue={returntypes[0]}
              ></SearchList>
            </div>
            {/* <SearchList
              heading="Select Period"
              list={
                this.state.selectedReturnType === "GSTR-3B Quarterly"
                  ? quarterTillLastQuarter()
                  : monthTillLastMonth()
              }
              handleValue={this.handleValuePeriod}
              defaultValue={
                this.state.selectedReturnType === "GSTR-3B Quarterly"
                  ? quarterTillLastQuarter()[0]
                  : monthTillLastMonth()[0]
              }
            ></SearchList> */}
            <div>
              {this.state.selectedReturnType === "GSTR-3B Quarterly" ? (
                <SearchList
                  heading="Select Period"
                  list={quarterTillLastQuarter()}
                  handleValue={this.handleValuePeriod}
                  defaultValue={quarterTillLastQuarter()[0]}
                ></SearchList>
              ) : this.state.selectedReturnType === "GSTR-3B Monthly" ? (
                <SearchList
                  heading="Select Period"
                  list={monthTillLastMonth()}
                  handleValue={this.handleValuePeriod}
                  defaultValue={monthTillLastMonth()[0]}
                ></SearchList>
              ) : this.state.selectedReturnType === "GSTR-1 Quarterly" ? (
                <SearchList
                  heading="Select Quarter"
                  list={quarterTillLastQuarter()}
                  handleValue={this.handleValuePeriod}
                  defaultValue={quarterTillLastQuarter()[0]}
                ></SearchList>
              ) : this.state.selectedReturnType === "GSTR-1 Monthly" ? (
                <SearchList
                  heading="Select Period"
                  list={monthTillLastMonth()}
                  handleValue={this.handleValuePeriod}
                  defaultValue={monthTillLastMonth()[0]}
                ></SearchList>
              ) : this.state.selectedReturnType === "IFF" ? (
                <SearchList
                  heading="Select Period"
                  list={monthTillLastMonthIFF()}
                  handleValue={this.handleValuePeriod}
                  defaultValue={monthTillLastMonthIFF()[0]}
                ></SearchList>
              ) : this.state.selectedReturnType === "GSTR-9" ? (
                <SearchList
                  heading="Select Period"
                  list={yearTillLastYear()}
                  handleValue={this.handleValuePeriod}
                  defaultValue={yearTillLastYear()[0]}
                ></SearchList>
              ) : this.state.selectedReturnType === "GSTR-4" ? (
                <SearchList
                  heading="Select Period"
                  list={yearTillLastYear()}
                  handleValue={this.handleValuePeriod}
                  defaultValue={yearTillLastYear()[0]}
                ></SearchList>
              ) : this.state.selectedReturnType === "CMP-08" ? (
                <SearchList
                  heading="Select Period"
                  list={quarterTillLastQuarter()}
                  handleValue={this.handleValuePeriod}
                  defaultValue={quarterTillLastQuarter()[0]}
                ></SearchList>
              ) : (
                ""
              )}
            </div>
            <div>
              <MultiSelect
                heading="Select Status"
                list={["Filed", "Not Filed", "Not Checked"]}
                handleValue={this.handleValueStatus}
                defaultValue={[]}
                placeholder="Showing All Status"
              ></MultiSelect>
            </div>
            <div>
              <MultiSelect
                heading="Select Tags"
                list={this.state.tagList.map((tag: any) => tag.name)}
                handleValue={this.handleValueTag}
                defaultValue={[]}
                placeholder="Showing All Tags"
              ></MultiSelect>
            </div>
          </div>
          <div className="space-x-4 max-w-7xl mx-auto px-4 py-3 sm:px-6 md:px-8">
            {/* <Link to={"/gsts/add"}> */}
            <button
              type="button"
              disabled={
                this.state.loading || this.state.selectedReturnType === "GSTR-4"
              }
              onClick={() => this.getReturnStatus(false, true)}
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-300 disabled:cursor-not-allowed"
            >
              <Icon
                name="outline/list"
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              List Return Status
            </button>
            <span className="text-sm font-semibold text-gray-900">
              {this.state.selectedReturnType === "GSTR-4"
                ? "Note - GSTN is not sending the GSTR-4 return status from last few days. We have raised the concern to relevant department."
                : ""}
            </span>
            {/*             <button
              type="button"
              disabled={this.state.loading}
              onClick={this.handleReset}
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            >
              Reset
            </button>
 */}{" "}
          </div>
          {/*           <div>
            <label htmlFor="combobox" className="block text-sm font-medium text-gray-700">
              Assigned to
            </label>
            <div className="relative mt-1">
              <input id="combobox" type="text" className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" role="combobox" aria-controls="options" aria-expanded="false" />
              <button type="button" className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                // <!-- Heroicon name: solid/selector -->
                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>

              <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" id="options" role="listbox">
                <!--
                  Combobox option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

                   Active: "text-white bg-indigo-600", Not Active: "text-gray-900"
                  -->
                 <li className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900" id="option-0">
                  <!-- Selected: "font-semibold" -->
                  <span className="block truncate">Leslie Alexander</span>

                  <!--
                    Checkmark, only display for selected option.

                    Active: "text-white", Not Active: "text-indigo-600"
                   -->
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                    <!-- Heroicon name: solid/check -->
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </li>

                <!-- More items... -->
              </ul>
            </div>
          </div> */}
          {/* <EditNameModal gstId={this.state.selectedTagId} openModal={this.state.modalOpen} handleModalChange={this.handleModalChange} /> */}
          {!this.state.loading && this.state.displayGSTReturnDetails ? (
            this.state.totalRecords > 0 || this.state.searchText.length > 0 ? (
              <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
                <div className="py-12">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="text-xl	text-center whitespace-normal pt-0 pb-3 text-decoration-line: underline font-medium	text-gray-900">
                        Return status of {this.state.tableHeadingReturnType} for
                        the period {this.state.tableHeadingReturnPeriod}
                      </div>
                      <div>
                        <div className="grid grid-cols-3 basis-2 md:px-8 py-2">
                          <div className="justify-self-start">
                            <button
                              disabled={this.state.loading}
                              type="button"
                              onClick={() =>
                                this.rStatusUpdate(
                                  [],
                                  this.state.selectedPeriod,
                                  this.state.selectedReturnType,
                                  false,
                                  false
                                )
                              }
                              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-300 disabled:cursor-not-allowed"
                            >
                              <Icon
                                name="outline/update"
                                className="-ml-1 mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              Update Return Status
                            </button>
                          </div>
                          <div>
                            <div className="justify-self-stretch items-center">
                              <input
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Search by Legal Name, Trade Name or GSTIN"
                                value={this.state.searchText}
                                onChange={(ev: any) => {
                                  this.handleSearchTextChange(ev);
                                }}
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="justify-self-end">
                            <button
                              disabled={
                                this.state.loading || this.state.downloading
                              }
                              type="button"
                              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-300 disabled:cursor-not-allowed"
                              onClick={() => {
                                this.getReturnStatus(true, true);
                              }}
                            >
                              <Icon
                                name={this.state.exportIcon}
                                className="-ml-1 mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                              Export as XLSX
                            </button>
                          </div>
                        </div>
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider divide-y divide-gray-200"
                                  >
                                    Legal Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Trade Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    GST Number
                                  </th>
                                  {/* <th
                                  scope="col"
                                  className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Filling Frequency
                                </th> */}
                                  <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Status
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Date of Filling
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Last Checked
                                  </th>
                                  <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Check Status
                                  </th>
                                  {/* <th scope="col" className="w-4/12 px-4 py-3 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Filing Frequency
                                  <table>
                                    <thead>
                                      <tr>
                                        <th id="Q1" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q1 <br /> Apr-Jun
                                        </th>
                                        <th id="Q2" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q2 <br /> Jul-Sep
                                        </th>
                                        <th id="Q3" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q3
                                          <br /> Oct-Dec
                                        </th>
                                        <th id="Q4" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q4
                                          <br /> Jan-Mar
                                        </th>
                                      </tr>
                                    </thead>
                                  </table>
                                </th> */}
                                </tr>
                              </thead>
                              {this.state.totalRecords === 0 ? (
                                <div className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                  No record found matching your search criteria
                                </div>
                              ) : (
                                <tbody>
                                  {this.state.displayGSTReturnDetails.map(
                                    (returnDetails: any) => (
                                      <tr
                                        key={returnDetails.id}
                                        className="bg-white"
                                      >
                                        <td
                                          key={returnDetails.id}
                                          className="w-2/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                        >
                                          {returnDetails.lgnm}
                                          {/* <button type="button" onClick={() => this.editGSTName(tag._id)} className="ml-3 items-center px-1 py-1 border border-transparent text-xs rounded text-black bg-white hover:bg-gray-200 focus:outline-none">
                                      <Icon name="edit" className="mx-auto flex-shrink-0 h-4 w-4" />
                                          </button> */}
                                        </td>
                                        <td
                                          key={returnDetails.id}
                                          className="w-2/10 px-6 py-3 whitespace-wrap text-sm text-gray-500"
                                        >
                                          {returnDetails.name}
                                        </td>
                                        <td
                                          key={returnDetails.id}
                                          className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                        >
                                          {returnDetails.gstin}
                                        </td>
                                        <td
                                          key={returnDetails.id}
                                          className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                        >
                                          {returnDetails.status}
                                        </td>
                                        <td
                                          key={returnDetails.id}
                                          className="w-1/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                        >
                                          {returnDetails.dof
                                            ? new Date(
                                                returnDetails.dof
                                              ).toLocaleDateString()
                                            : "-"}
                                        </td>
                                        {/* <td
                                      key={tag.id}
                                      className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                    >
                                      {tag.filingFrequency === "Q"
                                        ? "Quarterly"
                                        : tag.filingFrequency === "M"
                                        ? "Monthly"
                                        : tag.filingFrequency}
                                    </td> */}
                                        <td
                                          key={returnDetails.id}
                                          className="w-1/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                        >
                                          {returnDetails.lastChecked
                                            ? new Date(
                                                returnDetails.lastChecked
                                              ).toLocaleDateString() +
                                              " - " +
                                              new Date(
                                                returnDetails.lastChecked
                                              ).toLocaleTimeString("en-US", {
                                                hour: "2-digit",
                                                minute: "2-digit"
                                              })
                                            : "-"}
                                        </td>
                                        <td
                                          key={returnDetails.id}
                                          className="w-2/10 px-6 py-3 whitespace-wrap text-sm text-gray-500"
                                        >
                                          {
                                            /* 
                                            This conditional rendering checks if the selected return type is "IFF" and if the last checked date is later than the selected period.
                                            It also checks if the date of the last check is greater than or equal to 14.
                                            If these conditions are met or if the status of returnDetails is neither "Not Filed" nor "Not Checked", it renders a "-".
                                            Otherwise, it will render a div with the text "Check". This div has a click event handler that triggers the rStatusUpdate function with specific parameters.
                                            */
                                            (this.state.selectedReturnType ===
                                              "IFF" &&
                                              new Date(
                                                returnDetails.lastChecked
                                              ).getMonth() >=
                                                calendarMonths.indexOf(
                                                  this.state.selectedPeriod.split(
                                                    "-"
                                                  )[1]
                                                ) &&
                                              new Date(
                                                returnDetails.lastChecked
                                              ).getDate() >= 14) ||
                                            ![
                                              "Not Filed",
                                              "Not Checked"
                                            ].includes(returnDetails.status) ? (
                                              "-"
                                            ) : (
                                              <button
                                                disabled={this.state.loading}
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  this.rStatusUpdate(
                                                    [returnDetails.gstin],
                                                    this.state
                                                      .tableHeadingReturnPeriod,
                                                    this.state
                                                      .tableHeadingReturnType,
                                                    returnDetails.status ===
                                                      "Not Filed",
                                                    true
                                                  );
                                                }}
                                              >
                                                Check
                                              </button>
                                            )
                                          }
                                        </td>
                                        {/* <td key={tag.id} className="w-1/10 px-6 py-3 whitespace-wrap text-sm text-gray-500">
                                    {tag.cxdt ? new Date(tag.cxdt).toLocaleDateString() : "-"}
                                  </td> */}
                                        {/* <td>
                                    <table>
                                      <tbody>
                                        <tr className="flex w-4/10 pl-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q1")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q2")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q3")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q4")}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td> */}
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                <Icon
                  name="outline/document-add"
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  strokeWidth="1"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  GSTIN not found
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by adding a new GSTN.
                </p>
                <div className="mt-6">
                  <Link to={"/gsts/add"}>
                    <button
                      disabled={this.state.loading}
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-300 disabled:cursor-not-allowed"
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      ADD GSTN
                    </button>
                  </Link>
                </div>
              </div>
            )
          ) : (
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Legal Name
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Trade Name
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                GST Number
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Date of filing
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Last Checked
                              </th>
                              {/* <th
                                scope="col"
                                className="w-4/12 px-4 py-3 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Filing Frequency
                                <table>
                                  <thead>
                                    <tr>
                                      <th
                                        id="Q1"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q1 <br /> Apr-Jun
                                      </th>
                                      <th
                                        id="Q2"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q2 <br /> Jul-Sep
                                      </th>
                                      <th
                                        id="Q3"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q3
                                        <br /> Oct-Dec
                                      </th>
                                      <th
                                        id="Q4"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q4
                                        <br /> Jan-Mar
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {[...Array(4)].map((e, i) => (
                              <tr key={i} className="bg-white">
                                {[...Array(6)].map((e, i) => (
                                  <td
                                    key={i}
                                    className="w-full px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                  >
                                    <Skeleton />
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.totalRecords > 0 ? (
            <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {this.state.currPage * this.state.chunkSize + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {(this.state.currPage + 1) * this.state.chunkSize >
                      this.state.totalRecords
                        ? this.state.totalRecords
                        : (this.state.currPage + 1) * this.state.chunkSize}
                    </span>{" "}
                    of{" "}
                    <span className="font-medium">
                      {this.state.totalRecords}
                    </span>{" "}
                    results
                  </p>
                </div>
                <div className="text-sm text-gray-700 flex items-center ml-3 mr-3 gap-2">
                  <div>Items per page</div>
                  <div className="data-dropdown-placement={top}">
                    <SelectMenu
                      items={itemsPerPage}
                      selected={{ name: this.state.chunkSize }}
                      onChange={this.handleItemPerPage}
                    />
                  </div>
                </div>
              </div>
              <div
                id="pagination"
                className="text-sm text-gray-500 flex items-center"
              >
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(
                    this.state.totalRecords / this.state.chunkSize
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(Rstatus) as ComponentType;
