const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const quarterPeriod = [
  { first: "Jan", last: "Mar" },
  { first: "Apr", last: "Jun" },
  { first: "Jul", last: "Sep" },
  { first: "Oct", last: "Dec" }
];

export type Month = (typeof months)[number];

export type MonthPeriod = `${Month} ${number}`;
export type QuarterPeriod = `${Month} ${number} to ${Month} ${number}`;

export function getQuarterFromMonthOrQuarter(
  monthOrQuarter: Month | QuarterPeriod
) {
  const month = monthOrQuarter.split(" ")[0].slice(0, 3) as Month;
  const year = monthOrQuarter.split(" ")[1];
  const quarterIndex = Math.floor(months.indexOf(month) / 3);
  const startQuarter = quarterPeriod[quarterIndex];
  return `${startQuarter.first} ${year} to ${startQuarter.last} ${year}` as const;
}
