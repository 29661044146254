let config: {
  baseURL: string;
};

if (process.env.REACT_APP_NODE_ENV === "production") {
  config = {
    baseURL: "https://rsc.taxpido.com/api"
  };
} else if (process.env.REACT_APP_NODE_ENV === "staging") {
  config = {
    baseURL: "https://rscdev.taxpido.com/api"
  };
} else {
  config = {
    baseURL: "http://localhost:5001/api"
  };
}

export default config;
