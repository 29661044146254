import { Disclosure, Transition } from "@headlessui/react";
import {
  ArrowTopRightOnSquareIcon,
  BugAntIcon,
  CalculatorIcon,
  ChevronDownIcon,
  CodeBracketIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  LinkIcon,
  PresentationChartLineIcon,
  ShieldExclamationIcon,
  UserCircleIcon
} from "@heroicons/react/24/outline";
import React, { createRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import agent from "../../agent";
import months from "../../constants/months";
import years from "../../constants/years";
import {
  ADD_NOTIFICATION,
  GET_GSTS,
  LOGOUT,
  UPDATE_COMMON
} from "../../store/types";
import Icon from "../Icon";
import SelectMenu from "../SelectMenu";
import { WithRouterProps, withRouter } from "../../helpers/withRouter";
import MessageBar from "../MessageBar";

const menuItems = [
  {
    name: "GSTIN List",
    iconName: "outline/users",
    route: "/gsts"
  },
  {
    name: "Return Status",
    iconName: "outline/receipt-tax",
    route: "/rstatus"
  },
  {
    name: "QRMP",
    iconName: "outline/clipboard-list",
    route: "/QRMP"
  },
  {
    name: "TAGS",
    iconName: "outline/tag",
    route: "/tags"
  }
];

type LinkProps = { onlyIcon?: boolean };

const helpfulLinks = [
  // {
  //   name: "Knowledge Base",
  //   IconName: InformationCircleIcon,
  //   link: "https://support.taxpido.com/"
  // },
  {
    name: "Change Log",
    IconName: CodeBracketIcon,
    link: "https://taxpido.com/rsc-change-log/"
  },
  {
    name: "Road Map",
    IconName: PresentationChartLineIcon,
    link: "https://taxpido.com/rsc-roadmap/"
  },
  {
    name: "RSC Status",
    IconName: ShieldExclamationIcon,
    link: "https://taxpido.com/rsc-status/"
  },
  {
    name: "Known Issues",
    IconName: BugAntIcon,
    link: "https://taxpido.com/rsc-known-issues/"
  },
  {
    name: "Bug Report/Contact Support",
    IconName: EnvelopeIcon,
    link: "https://forms.gle/VcCba6Zs9x3dFcD1A"
  }
];

const otherApplications = [
  {
    name: "Practice Management Software (PMS)",
    IconName: DocumentTextIcon,
    link: "https://taxpido.com/practice-management-software-for-ca-tax-professionals/"
  },
  {
    name: "Quick Login Tool",
    IconName: DocumentTextIcon,
    link: "https://login.taxpido.com/"
  },
  {
    name: "GST Return Tool",
    IconName: DocumentTextIcon,
    link: "https://gst.taxpido.com/"
  }
];

const otherLinks = [
  { label: "Helpful Links", IconName: LinkIcon, children: helpfulLinks },
  {
    label: "TaxPido Other Applications",
    IconName: LinkIcon,
    children: otherApplications
  }
];

const OtherLinks = ({ onlyIcon }: LinkProps) => {
  return (
    <>
      {otherLinks.map(({ label, IconName, children }) => (
        <Disclosure key={label}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`text-gray-300 hover:bg-gray-700 hover:text-white group flex justify-between items-center text-xs font-medium rounded-md w-full group ${
                  !onlyIcon && "px-2 py-2 gap-x-3"
                }`}
              >
                <span className="flex items-center gap-3">
                  <IconName className="text-gray-400 flex-shrink-0 h-5 w-5" />
                  {!onlyIcon && <span>{label}</span>}
                </span>
                {!onlyIcon && (
                  <ChevronDownIcon
                    className={`w-5 h-5 text-gray-400 transform ${
                      open ? "" : "-rotate-90"
                    }`}
                  />
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="space-y-1 ml-6 border-l border-gray-700">
                {children.map(({ name, IconName, link }) => (
                  <a
                    key={name}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center text-xs font-medium rounded-md ${
                      !onlyIcon && "px-2 py-2 gap-x-3"
                    }`}
                  >
                    <IconName className="text-gray-400 flex-shrink-0 h-5 w-5" />
                    {!onlyIcon && (
                      <>
                        <span>{name}</span>
                        <ArrowTopRightOnSquareIcon className="text-gray-400 flex-shrink-0 h-4 w-4 ml-auto" />
                      </>
                    )}
                  </a>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </>
  );
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common,
  ...state.gsts,
  ...state.notification
});

const mapDispatchToProps = (dispatch: any) => ({
  onLoad: (gsts: any) => dispatch({ type: GET_GSTS, payload: { gsts } }),
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> &
  Partial<WithRouterProps>;

interface DashboardProps extends PropsFromRedux {
  children: any;
}

class Dashboard extends React.Component<DashboardProps> {
  state: {
    menuShow: boolean;
    gsts: any;
    loading: boolean;
    menuState: any;
    subMenuState: any;
    profileMenuShow: boolean;
    showInfoFooter: boolean;
    infoFooterHeight?: number;
  };

  constructor(props: DashboardProps) {
    super(props);

    this.state = {
      gsts: "",
      loading: false,
      menuShow: false,
      menuState: {},
      subMenuState: {},
      profileMenuShow: false,
      showInfoFooter: true,
      infoFooterHeight: 0
    };
  }

  infoFooterRef = createRef<HTMLDivElement>();

  showHideInfoFooter = () => {
    this.setState({ showInfoFooter: !this.state.showInfoFooter });
  };

  componentDidMount = () => {
    document.title = "TaxPido RSC | Dashboard";

    this.setState({
      infoFooterHeight: this.infoFooterRef.current
        ? this.infoFooterRef.current?.clientHeight
        : 0
    });

    this.getUserDetails();

    /* this.updateRoute();
    if ((this.props as any).gsts) {
      this.setState({ gsts: (this.props as any).gsts });
    } else {
      this.setState({ loading: true });
      agent.Gst.getAll({ download: false })
        .then((response: any) => {
          this.setState({ gsts: response.gsts, loading: false });
          if (response.gsts?.length) {
            (this.props as any).updateCommon({
              currentGst: response.gsts[0],
              currentYear: years[0].name,
              currentMonth: months[0].name
            });
          }
          (this.props as any).onLoad(response.gsts);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
        });
    } */
  };

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if ((this.props as any).gsts !== prevProps.gsts) {
      this.setState({
        gsts: (this.props as any).gsts
      });
      (this.props as any).updateCommon({
        currentGst: (this.props as any).gsts[0],
        currentYear: years[0].name,
        currentMonth: months[0].name
      });
    }
    if ((this.props as any).match !== prevProps.match) {
      this.updateRoute();
    }

    if (
      // this.infoFooterRef.current &&
      prevState.showInfoFooter !== this.state.showInfoFooter
    ) {
      this.setState({
        infoFooterHeight: this.state.showInfoFooter
          ? this.infoFooterRef.current?.clientHeight || 0
          : 0
      });
    }
  };

  getUserDetails = () => {
    agent.Auth.getUserDetails()
      .then((response: { user: { email: string; mobilenumber: string } }) => {
        (this.props as any).updateCommon({
          email: response.user.email,
          mobile: response.user.mobilenumber
        });
      })
      .catch((err: any) => {
        (this.props as any).onNotify(
          "Error",
          "Could not fetch user details",
          "danger"
        );
      });
  };

  updateRoute = () => {
    let path = (this.props as any).match?.path;
    let menuState: any = {};
    let subMenuState: any = {};
    /* menuItems.forEach(menuItem => {
      if (path.indexOf(menuItem.route) === 0) {
        menuState[menuItem.name] = true;
      }
      menuItem.children?.forEach(subItem => {
        if (path.indexOf(subItem.route) === 0) {
          subMenuState[subItem.name] = true;
        }
      });
    }); */
    this.setState({ menuState, subMenuState });
  };

  onGstChange = (item: any) => {
    (this.props as any).updateCommon({ currentGst: item });
  };

  onYearChange = (item: any) => {
    (this.props as any).updateCommon({ currentYear: item.name });
  };

  onMonthChange = (item: any) => {
    (this.props as any).updateCommon({ currentMonth: item.name });
  };

  toggleDropdown = (name: any) => {
    if (this.state.menuState[name]) {
      this.setState({ menuState: {} });
    } else {
      this.setState({ menuState: { [name]: true } });
    }
  };

  toggleSubItemDropdown = (name: any) => {
    if (this.state.subMenuState[name]) {
      this.setState({ subMenuState: {} });
    } else {
      this.setState({ subMenuState: { [name]: true } });
    }
  };

  toggleMenu = () => {
    this.setState({ menuShow: !this.state.menuShow });
  };

  toggleProfileMenu = () => {
    this.setState({ profileMenuShow: !this.state.profileMenuShow });
  };

  logout = () => {
    (this.props as any).onLogout();
    (this.props as any).onNotify(
      "Successful",
      "You have been successfully logged out.",
      "success"
    );
  };

  render() {
    return (
      <div
        style={{
          height: `calc(100vh - ${this.state.infoFooterHeight}px)`
        }}
        className="h-screen flex overflow-hidden bg-gray-100"
      >
        {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}
        <div
          className={`fixed inset-0 md:hidden ${
            this.state.menuShow ? "z-40" : "-z-40"
          }`}
          role="dialog"
          aria-modal="true"
        >
          <Transition
            show={this.state.menuShow}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-75"
              aria-hidden="true"
            ></div>
          </Transition>

          <Transition
            show={this.state.menuShow}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            className="h-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full h-full pt-5 pb-4 bg-gray-800">
              <Transition
                show={this.state.menuShow}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                    onClick={this.toggleMenu}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <Icon name="outline/x" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </Transition>

              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  src="/images/Taxpido_logo_white.png"
                  alt="Taxpido Logo"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {menuItems.map((menuItem: any) =>
                    !menuItem.children ? (
                      <div key={menuItem.name}>
                        <Link
                          to={menuItem.route}
                          key={menuItem.name}
                          className={
                            this.props.location?.pathname === menuItem.route
                              ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          }
                        >
                          <Icon
                            name={menuItem.iconName}
                            className={
                              this.state.menuState[menuItem.name]
                                ? "text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                                : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                            }
                            aria-hidden="true"
                          />
                          {menuItem.name}
                        </Link>
                      </div>
                    ) : (
                      <div key={menuItem.name}>
                        <Disclosure
                          as="div"
                          key={menuItem.name}
                          className="space-y-1"
                        >
                          {({ open }) => (
                            <div
                              onClick={() => this.toggleDropdown(menuItem.name)}
                            >
                              <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                <span className="flex items-center">
                                  <Icon
                                    name={menuItem.iconName}
                                    className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                  {menuItem.name}
                                </span>
                                <ChevronDownIcon
                                  className={
                                    !this.state.menuState[menuItem.name]
                                      ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                      : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                  }
                                />
                              </Disclosure.Button>
                              {this.state.menuState[menuItem.name] ? (
                                <Disclosure.Panel
                                  className="space-y-1 ml-11 border-l border-gray-500"
                                  static
                                >
                                  {menuItem.children.map((subItem: any) =>
                                    !subItem.children ? (
                                      <div key={subItem.name}>
                                        <Link
                                          to={subItem.route}
                                          key={subItem.name}
                                          className={
                                            this.props.location?.pathname ===
                                            subItem.route
                                              ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                              : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                          }
                                        >
                                          {subItem.name}
                                        </Link>
                                      </div>
                                    ) : (
                                      <div key={subItem.name}>
                                        <Disclosure
                                          as="div"
                                          key={subItem.name}
                                          className="space-y-1"
                                        >
                                          {({ open }) => (
                                            <div
                                              onClick={() =>
                                                this.toggleSubItemDropdown(
                                                  subItem.name
                                                )
                                              }
                                            >
                                              <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                                <span className="flex items-center">
                                                  {subItem.name}
                                                </span>
                                                <ChevronDownIcon
                                                  className={
                                                    !this.state.subMenuState[
                                                      subItem.name
                                                    ]
                                                      ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                      : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                  }
                                                />
                                              </Disclosure.Button>
                                              {this.state.subMenuState[
                                                subItem.name
                                              ] ? (
                                                <Disclosure.Panel
                                                  className="space-y-1 ml-6 border-l border-gray-500"
                                                  static
                                                >
                                                  {subItem.children.map(
                                                    (subSubItem: any) => (
                                                      <Link
                                                        to={subSubItem.route}
                                                        key={subSubItem.name}
                                                        className={
                                                          this.props.location
                                                            ?.pathname ===
                                                          subSubItem.route
                                                            ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                            : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                        }
                                                      >
                                                        {subSubItem.name}
                                                      </Link>
                                                    )
                                                  )}
                                                </Disclosure.Panel>
                                              ) : null}
                                            </div>
                                          )}
                                        </Disclosure>
                                      </div>
                                    )
                                  )}
                                </Disclosure.Panel>
                              ) : null}
                            </div>
                          )}
                        </Disclosure>
                      </div>
                    )
                  )}
                  <OtherLinks />
                </nav>
              </div>
            </div>
          </Transition>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
          </div>
        </div>

        {/* <!-- Static sidebar for desktop --> */}
        <div className="hidden md:flex md:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
            <div className="flex flex-col h-0 flex-1">
              <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
                <img
                  className="h-8 m-auto w-auto"
                  src="/images/Taxpido_logo_white.png"
                  alt="Taxpido Logo"
                />
              </div>
              <div className="flex-1 flex flex-col overflow-y-auto">
                <nav className="flex-1 px-2 py-4 bg-gray-800 space-y-1">
                  {menuItems.map((menuItem: any) =>
                    !menuItem.children ? (
                      <div key={menuItem.name}>
                        <Link
                          to={menuItem.route}
                          key={menuItem.name}
                          className={
                            this.props.location?.pathname === menuItem.route
                              ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          }
                        >
                          <Icon
                            name={menuItem.iconName}
                            className={
                              this.state.menuState[menuItem.name]
                                ? "text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                                : "text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                            }
                            aria-hidden="true"
                          />
                          {menuItem.name}
                        </Link>
                      </div>
                    ) : (
                      <div key={menuItem.name}>
                        <Disclosure
                          as="div"
                          key={menuItem.name}
                          className="space-y-1"
                        >
                          {({ open }) => (
                            <div>
                              <div
                                onClick={() => {
                                  this.toggleDropdown(menuItem.name);
                                }}
                              >
                                <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                  <span className="flex items-center">
                                    <Icon
                                      name={menuItem.iconName}
                                      className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    {menuItem.name}
                                  </span>
                                  <ChevronDownIcon
                                    className={
                                      !this.state.menuState[menuItem.name]
                                        ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                        : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                    }
                                  />
                                </Disclosure.Button>
                              </div>
                              {this.state.menuState[menuItem.name] ? (
                                <Disclosure.Panel
                                  className="space-y-1 ml-11 border-l border-gray-500"
                                  static
                                >
                                  {menuItem.children.map((subItem: any) =>
                                    !subItem.children ? (
                                      <div key={subItem.name}>
                                        <Link
                                          to={subItem.route}
                                          key={subItem.name}
                                          className={
                                            this.props.location?.pathname ===
                                            subItem.route
                                              ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                              : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                          }
                                        >
                                          {subItem.name}
                                        </Link>
                                      </div>
                                    ) : (
                                      <div key={subItem.name}>
                                        <Disclosure
                                          as="div"
                                          key={subItem.name}
                                          className="space-y-1"
                                        >
                                          {({ open }) => (
                                            <div
                                              onClick={() =>
                                                this.toggleSubItemDropdown(
                                                  subItem.name
                                                )
                                              }
                                            >
                                              <Disclosure.Button className="text-gray-300 w-full justify-between hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                                                <span className="flex items-center">
                                                  <Icon
                                                    name={subItem.iconName}
                                                    className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                                    aria-hidden="true"
                                                  />
                                                  {subItem.name}
                                                </span>
                                                <ChevronDownIcon
                                                  className={
                                                    !this.state.subMenuState[
                                                      subItem.name
                                                    ]
                                                      ? "text-gray-400 -rotate-90 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                      : "text-gray-300 ml-3 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                  }
                                                />
                                              </Disclosure.Button>
                                              {this.state.subMenuState[
                                                subItem.name
                                              ] ? (
                                                <Disclosure.Panel
                                                  className="space-y-1 ml-6 border-l border-gray-500"
                                                  static
                                                >
                                                  {subItem.children.map(
                                                    (subSubItem: any) => (
                                                      <Link
                                                        to={subSubItem.route}
                                                        key={subSubItem.name}
                                                        className={
                                                          this.props.location
                                                            ?.pathname ===
                                                          subSubItem.route
                                                            ? "bg-gray-900 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                            : "text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                        }
                                                      >
                                                        {subSubItem.name}
                                                      </Link>
                                                    )
                                                  )}
                                                </Disclosure.Panel>
                                              ) : null}
                                            </div>
                                          )}
                                        </Disclosure>
                                      </div>
                                    )
                                  )}
                                </Disclosure.Panel>
                              ) : null}
                            </div>
                          )}
                        </Disclosure>
                      </div>
                    )
                  )}
                  <OtherLinks />
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none md:hidden"
              onClick={this.toggleMenu}
            >
              <span className="sr-only">Open sidebar</span>
              <Icon
                name="outline/menu-alt-2"
                className="h-6 w-6"
                onClick={this.toggleMenu}
              />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              {this.state.gsts && this.state.gsts.length > 0 ? (
                <div className="flex-3 flex">
                  <div className="ml-4 mr-4 flex">
                    <SelectMenu
                      items={this.state.gsts.map((gst: any) => {
                        return {
                          ...gst,
                          name: gst.lgnm
                        };
                      })}
                      selected={{ name: (this.props as any).currentGst?.lgnm }}
                      onChange={this.onGstChange}
                    />
                  </div>
                  <div className="ml-4 mr-4 flex">
                    <SelectMenu
                      items={years}
                      selected={{ name: (this.props as any).currentYear }}
                      onChange={this.onYearChange}
                    />
                  </div>
                  <div className="ml-4 mr-4 flex">
                    <SelectMenu
                      items={months}
                      selected={{ name: (this.props as any).currentMonth }}
                      onChange={this.onMonthChange}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex-3 flex"></div>
              )}
              <div className="ml-4 flex items-center md:ml-6">
                {/*   <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none">
                  <span className="sr-only">View notifications</span>
                  <Icon name="outline/bell" className="h-6 w-6" />
                </button>
 */}
                {/* <!-- Profile dropdown --> */}
                <div className="flex flex-row gap-12">
                  <div>
                    <Link to={"/subscriptions"}>
                      <button
                        type="button"
                        className="items-center px-4 py-2 flex border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        <Icon
                          name="outline/credit-card"
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Manage Subscription
                      </button>
                    </Link>
                  </div>
                  <div className="relative">
                    <div>
                      <button
                        type="button"
                        className="max-w-xs right-5 bg-white flex items-center text-sm rounded-full focus:outline-none"
                        id="user-menu-button"
                        aria-expanded="false"
                        aria-haspopup="true"
                        onClick={this.toggleProfileMenu}
                      >
                        <span className="sr-only">Open user menu</span>
                        <UserCircleIcon className="h-10 w-10" />
                      </button>
                    </div>

                    <Transition
                      show={this.state.profileMenuShow}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu-button"
                        tabIndex={-1}
                      >
                        {/* <Link
                        to="/"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        tabIndex={-1}
                        id="user-menu-item-0"
                      >
                        Your Profile
                      </Link> */}

                        <Link
                          to="/"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          tabIndex={-1}
                          id="user-menu-item-2"
                          onClick={this.logout}
                        >
                          Sign out
                        </Link>
                      </div>
                    </Transition>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <main className="flex-1 relative overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {this.props.children}

                <div ref={this.infoFooterRef} className={`fixed md:relative`}>
                  <MessageBar
                    showInfoFooter={this.state.showInfoFooter}
                    showHideInfoFooter={this.showHideInfoFooter}
                    infoFooterHeight={this.state.infoFooterHeight}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default compose(
  connector,
  withRouter
)(Dashboard) as React.ComponentType<DashboardProps>;
