import { reverse } from "dns";

export const monthTillLastMonth = () => {
  const currentMonth = getCurrentMonth();
  let startingMonth: any = "January 2021";
  let months: any = ["January 2021"];

  for (let i = 0; i < 1000; i++) {
    const nextMon = nextMonth(startingMonth);
    months.push(nextMon);

    if (nextMon === currentMonth) {
      break;
    }
    startingMonth = nextMon;
  }
  months = months.slice(0, months.length - 1);

  return months.reverse();
};

function nextMonth(quarter: string) {
  const parts = quarter.split(" ");
  if (parts[0] === "January") {
    return `February ${parts[1]}`;
  }
  if (parts[0] === "February") {
    return `March ${parts[1]}`;
  }
  if (parts[0] === "March") {
    return `April ${parts[1]}`;
  }
  if (parts[0] === "April") {
    return `May ${parts[1]}`;
  }
  if (parts[0] === "May") {
    return `June ${parts[1]}`;
  }
  if (parts[0] === "June") {
    return `July ${parts[1]}`;
  }
  if (parts[0] === "July") {
    return `August ${parts[1]}`;
  }
  if (parts[0] === "August") {
    return `September ${parts[1]}`;
  }
  if (parts[0] === "September") {
    return `October ${parts[1]}`;
  }
  if (parts[0] === "October") {
    return `November ${parts[1]}`;
  }
  if (parts[0] === "November") {
    return `December ${parts[1]}`;
  }
  if (parts[0] === "December") {
    return `January ${parseInt(parts[1]) + 1}`;
  }
}

function getCurrentMonth() {
  const todayDate1 = new Date();
  todayDate1.setMonth(todayDate1.getMonth());
  const currentMonth = todayDate1.toLocaleString("default", { month: "long" });
  const currentYear = todayDate1.getFullYear();
  const currentMonthYear = currentMonth + " " + currentYear;
  return currentMonthYear;
}

export const quarterTillCurrentQuarter = () => {
  const currentQuarter = getCurrentQuarter();
  let startingQuarter: any = "Jan 21 to Mar 21";
  let quarters: any = ["Jan 21 to Mar 21"];

  for (let i = 0; i < 1000; i++) {
    const nextQuar = nextQuarter(startingQuarter);
    quarters.push(nextQuar);

    if (nextQuar === currentQuarter) {
      break;
    }
    startingQuarter = nextQuar;
  }

  return quarters;
};

export const quarterTillLastQuarter = () => {
  const currentQuarter = getCurrentQuarter();
  let startingQuarter: any = "Jan 21 to Mar 21";
  let quarters: any = ["Jan 21 to Mar 21"];

  for (let i = 0; i < 1000; i++) {
    const nextQuar = nextQuarter(startingQuarter);
    quarters.push(nextQuar);

    if (nextQuar === currentQuarter) {
      break;
    }
    startingQuarter = nextQuar;
  }
  quarters = quarters.slice(0, quarters.length - 1);

  return quarters.reverse();
};

function nextQuarter(quarter: string) {
  const parts = quarter.split(" ");
  if (parts[0] === "Jan") {
    return `Apr ${parts[1]} to Jun ${parts[1]}`;
  }
  if (parts[0] === "Apr") {
    return `Jul ${parts[1]} to Sep ${parts[1]}`;
  }
  if (parts[0] === "Jul") {
    return `Oct ${parts[1]} to Dec ${parts[1]}`;
  }
  if (parts[0] === "Oct") {
    return `Jan ${parseInt(parts[1]) + 1} to Mar ${parseInt(parts[1]) + 1}`;
  }
}
function getCurrentQuarter() {
  const todayDate3 = new Date();
  todayDate3.setMonth(todayDate3.getMonth());
  const currentYear = todayDate3.getFullYear() - 2000;
  const month = todayDate3.toLocaleString("default", { month: "long" });
  if (month === "January" || month === "February" || month === "March") {
    return `Jan ${currentYear} to Mar ${currentYear}`;
  }
  if (month === "April" || month === "May" || month === "June") {
    return `Apr ${currentYear} to Jun ${currentYear}`;
  }
  if (month === "July" || month === "August" || month === "September") {
    return `Jul ${currentYear} to Sep ${currentYear}`;
  }
  if (month === "October" || month === "November" || month === "December") {
    return `Oct ${currentYear} to Dec ${currentYear}`;
  }
}

export const yearTillCurrentYear = () => {
  const currentYear = getCurrentFinYear();
  let startingYear = "2020-21";
  let years: any = ["2020-21"];

  for (let i = 0; i < 1000; i++) {
    const nextYear = nextFinYear(startingYear);
    years.push(nextYear);

    if (nextYear === currentYear) {
      break;
    }
    startingYear = nextYear;
  }

  return years.reverse();
};

export const yearTillLastYear = () => {
  const currentYear = getCurrentFinYear();
  let startingYear = "2020-21";
  let years: any = ["2020-21"];

  for (let i = 0; i < 1000; i++) {
    const nextYear = nextFinYear(startingYear);
    years.push(nextYear);

    if (nextYear === currentYear) {
      break;
    }
    startingYear = nextYear;
  }
  years = years.slice(0, years.length - 1);

  return years.reverse();
};

function nextFinYear(year: string) {
  const parts = year.split("-");

  return `${parseInt(parts[0]) + 1}-${parseInt(parts[1]) + 1}`;
}

function getCurrentFinYear() {
  const todayDate5 = new Date();
  todayDate5.setMonth(todayDate5.getMonth());
  const currentYear = todayDate5.getFullYear();
  const month = todayDate5.toLocaleString("default", { month: "long" });
  if (month === "January" || month === "February" || month === "March") {
    return currentYear - 1 + "-" + (currentYear - 2000);
  }
  return currentYear + "-" + (currentYear + 1 - 2000);
}

export const monthTillLastMonthIFF = () => {
  const currentMonth = getCurrentMonth();
  let startingMonth: any = "January 2021";
  let months: any = ["January 2021"];

  for (let i = 0; i < 1000; i++) {
    const nextMon: any = nextMonth(startingMonth);
    if (
      nextMon.split(" ")[0] !== "March" &&
      nextMon.split(" ")[0] !== "June" &&
      nextMon.split(" ")[0] !== "September" &&
      nextMon.split(" ")[0] !== "December"
    ) {
      months.push(nextMon);
    }
    if (nextMon === currentMonth) {
      break;
    }
    startingMonth = nextMon;
  }
  if (months[months.length - 1] === currentMonth) {
    months = months.slice(0, months.length - 1);
  }
  return months.reverse();
};

/* module.exports = {
  monthTillLastMonth: monthTillLastMonth,
  yearTillLastYear: yearTillLastYear
}; */

export default {
  monthTillLastMonth,
  quarterTillCurrentQuarter,
  yearTillLastYear,
  yearTillCurrentYear,
  monthTillLastMonthIFF
};
