import { PlusIcon } from "@heroicons/react/24/solid";
import React, { ComponentType } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
// Pagination
import ReactPaginate from "react-paginate";
import { connect, ConnectedProps } from "react-redux";
// Routing imports
import { Link } from "react-router-dom";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
import SelectMenu from "../../components/SelectMenu";
import years from "../../constants/years";
// Redux Notify
import { ADD_NOTIFICATION } from "../../store/types";
import "../style.css";
import EditNameModal from "./EditNameModal";
import TagManager from "react-gtm-module";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "tags"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping

const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & WithRouterProps;
class Tags extends React.Component<PropsFromRedux> {
  state: {
    loading: boolean;
    tagDetails: any;
    totalRecords: number;
    displayTagDetails: any;
    selectedTagId: string;
    modalOpen: boolean;
    //currentYear: any;
  };

  constructor(props: PropsFromRedux) {
    super(props);

    this.state = {
      loading: true,
      tagDetails: [],
      totalRecords: 0,
      displayTagDetails: [],
      selectedTagId: "",
      modalOpen: false
      //currentYear: years[0].name
    };
  }

  // Chunk Size for number of table data displayed in each page during pagination
  chunkSize = 5000;
  // Selected pagination value
  currPage = 0;

  //Get Tag Data

  getTagData = () => {
    agent.Tag.getAll()
      .then((response: any) => {
        this.setState({
          displayTagDetails: response.tags.slice(
            this.currPage * this.chunkSize,
            this.currPage * this.chunkSize + this.chunkSize
          ),
          tagDetails: response.tags,
          loading: false,
          totalRecords: response.count
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load Tag Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };
  //onMount Load data
  componentDidMount() {
    this.getTagData();
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.modalOpen !== this.state.modalOpen &&
      this.state.modalOpen === false
    ) {
      setTimeout(() => {
        this.getTagData();
      }, 100);
    }
  }

  handlePageClick = (data: any) => {
    this.currPage = data.selected;
    this.setState({
      displayTagDetails: this.state.tagDetails.slice(
        this.currPage * this.chunkSize,
        this.currPage * this.chunkSize + this.chunkSize
      )
    });
  };

  editGSTName = (gstid: string) => {
    this.setState({
      modalOpen: true,
      selectedTagId: gstid
    });
  };

  handleModalChange = (value: boolean) => {
    this.setState({
      modalOpen: value
    });
  };

  /* extractPreference = (filingFreq: any[], quarter: string) => {
    const prefArray = filingFreq.filter(item => item.quarter === quarter && item.year === this.state.currentYear);
    return prefArray.length === 0 ? "Unavailable" : prefArray[0].preference === "Q" ? "Quarterly" : "Monthly";
  }; */

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <div className="gsts">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">All Tags</h1>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="text-right">
              <Link to={"/tags/add"}>
                {/* <Link to={"/gsts/add"}> */}
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                >
                  Add Tag
                </button>
              </Link>
            </div>
          </div>

          <EditNameModal
            gstId={this.state.selectedTagId}
            openModal={this.state.modalOpen}
            handleModalChange={this.handleModalChange}
          />
          {!this.state.loading && this.state.displayTagDetails ? (
            this.state.totalRecords > 0 ? (
              <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
                {/* <div className="flex">
                  <SelectMenu items={years} selected={{ name: this.state.currentYear }} onChange={(year: any) => this.setState({ currentYear: year.name })} />
                </div> */}
                <div className="py-6">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider divide-y divide-gray-200"
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Description
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Number of Client
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Status
                                </th>
                                {/* <th scope="col" className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Registration Date
                                </th>
                                <th scope="col" className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Cancellation Date
                                </th> */}
                                {/* <th scope="col" className="w-4/12 px-4 py-3 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Filing Frequency
                                  <table>
                                    <thead>
                                      <tr>
                                        <th id="Q1" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q1 <br /> Apr-Jun
                                        </th>
                                        <th id="Q2" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q2 <br /> Jul-Sep
                                        </th>
                                        <th id="Q3" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q3
                                          <br /> Oct-Dec
                                        </th>
                                        <th id="Q4" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q4
                                          <br /> Jan-Mar
                                        </th>
                                      </tr>
                                    </thead>
                                  </table>
                                </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.displayTagDetails.map((tag: any) => (
                                <tr key={tag.id} className="bg-white">
                                  <td
                                    key={tag.id}
                                    className="w-2/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                  >
                                    {tag.name}
                                    {/* <button type="button" onClick={() => this.editGSTName(tag._id)} className="ml-3 items-center px-1 py-1 border border-transparent text-xs rounded text-black bg-white hover:bg-gray-200 focus:outline-none">
                                      <Icon name="edit" className="mx-auto flex-shrink-0 h-4 w-4" />
                                    </button> */}
                                  </td>
                                  <td
                                    key={tag.id}
                                    className="w-2/10 px-6 py-3 whitespace-wrap text-sm text-gray-500"
                                  >
                                    {tag.description}
                                  </td>
                                  <td
                                    key={tag.id}
                                    className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                  >
                                    {tag.gstincount}
                                  </td>
                                  <td
                                    key={tag.id}
                                    className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                  >
                                    {tag.active === true
                                      ? "Active"
                                      : "Inactive"}{" "}
                                    {/* RP Pending Work - Have to correct it */}
                                  </td>
                                  {/* <td key={tag.id} className="w-1/10 px-6 py-3 whitespace-wrap text-sm text-gray-500">
                                    {tag.rgdt ? new Date(tag.rgdt).toLocaleDateString() : "-"}
                                  </td>
                                  <td key={tag.id} className="w-1/10 px-6 py-3 whitespace-wrap text-sm text-gray-500">
                                    {tag.cxdt ? new Date(tag.cxdt).toLocaleDateString() : "-"}
                                  </td> */}
                                  {/* <td>
                                    <table>
                                      <tbody>
                                        <tr className="flex w-4/10 pl-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q1")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q2")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q3")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q4")}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                <Icon
                  name="outline/document-add"
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  strokeWidth="1"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No Tags Available
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by adding a new Tag.
                </p>
                <div className="mt-6">
                  <Link to={"/tags/add"}>
                    {/* <Link to={"/tags/add"}> */}
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      ADD TAG
                    </button>
                  </Link>
                </div>
              </div>
            )
          ) : (
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                GST Number
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Registered Date
                              </th>
                              <th
                                scope="col"
                                className="w-4/12 px-4 py-3 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Filing Frequency
                                <table>
                                  <thead>
                                    <tr>
                                      <th
                                        id="Q1"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q1 <br /> Apr-Jun
                                      </th>
                                      <th
                                        id="Q2"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q2 <br /> Jul-Sep
                                      </th>
                                      <th
                                        id="Q3"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q3
                                        <br /> Oct-Dec
                                      </th>
                                      <th
                                        id="Q4"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q4
                                        <br /> Jan-Mar
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {[...Array(4)].map((e, i) => (
                              <tr key={i} className="bg-white">
                                {[...Array(4)].map((e, i) => (
                                  <td
                                    key={i}
                                    className="w-full px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                  >
                                    <Skeleton />
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.totalRecords > this.chunkSize ? (
            <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {this.currPage * this.chunkSize + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {(this.currPage + 1) * this.chunkSize >
                      this.state.totalRecords
                        ? this.state.totalRecords
                        : (this.currPage + 1) * this.chunkSize}
                    </span>{" "}
                    of{" "}
                    <span className="font-medium">
                      {this.state.totalRecords}
                    </span>{" "}
                    results
                  </p>
                </div>
              </div>
              <div id="pagination" className="text-sm text-gray-500 my-2">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(
                    this.state.totalRecords / this.chunkSize
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(Tags) as ComponentType;
