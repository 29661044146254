import { PlusIcon } from "@heroicons/react/24/solid";
import React, { ComponentType } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
// Pagination
import ReactPaginate from "react-paginate";
import { connect, ConnectedProps } from "react-redux";
// Routing imports
import { Link } from "react-router-dom";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
import SelectMenu from "../../components/SelectMenu";
import years from "../../constants/years";
// Redux Notify
import { ADD_NOTIFICATION } from "../../store/types";
import "../style.css";
//import EditNameModal from "./EditNameModal";
import TagManager from "react-gtm-module";
import { downloadFile } from "../../helpers/downloadFile";
import itemsPerPage from "../../constants/itemsPerPage";
import DeleteModal from "../../components/DeleteModal";
import RequestProgress from "../../components/RequestProgress";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gsts"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping

const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & WithRouterProps;
class GSTs extends React.Component<PropsFromRedux> {
  state: {
    loading: boolean;
    logging: boolean;
    gstinType: string;
    selectedTag: string[];
    chunkSize: number;
    currPage: any;
    tagList: [];
    searchText: string;
    exportIcon: string;
    typing: boolean;
    typingTimeout: number;
    gstDetails: any;
    totalRecords: number;
    displayGstDetails: any;
    selectedGstId: string;
    modalOpen: boolean;
    currentYear: any;
    showDeleteModal: boolean;
    deleteId: boolean | undefined;
    showReqeustModal: boolean;
    requestId: string;
  };

  constructor(props: PropsFromRedux) {
    super(props);

    this.state = {
      loading: false,
      logging: false,
      gstinType: "",
      gstDetails: [],
      selectedTag: [""],
      chunkSize: 50,
      currPage: 0,
      tagList: [],
      searchText: "",
      exportIcon: "outline/download",
      typing: false,
      typingTimeout: 0,
      totalRecords: 0,
      displayGstDetails: [],
      selectedGstId: "",
      modalOpen: false,
      currentYear: years[0].name,
      showDeleteModal: false,
      deleteId: undefined,
      showReqeustModal: false,
      requestId: ""
    };
  }

  // Chunk Size for number of table data displayed in each page during pagination
  //chunkSize = 5000;
  // Selected pagination value
  //currPage = 0;

  //Get GST Data

  getGstData = (downloadtype: boolean, notification: boolean) => {
    let skip: number = this.state.chunkSize * this.state.currPage;
    let limit: number = this.state.chunkSize;
    let searchText = this.state.searchText;
    let download: boolean = downloadtype;
    let type: string = this.state.gstinType;
    let tags: string[] = this.state.selectedTag; //we have to send blank in case we do not want to filter GSTIN on the basis of tags

    this.setState({
      loading: true,
      exportIcon: downloadtype ? "loading" : "outline/download"
    });

    downloadtype === true //logic for download file option
      ? agent.Gst.getAll(skip, limit, searchText, download, type, tags)
          .then((response: any) => {
            downloadFile(
              response,
              `GST List-${
                new Date().toLocaleDateString() +
                " - " +
                new Date().toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit"
                })
              }.xlsx`
            );
            (this.props as any).onNotify(
              "Download",
              "Report has been successfully exported in excel",
              "success"
            );
            this.setState({ loading: false, exportIcon: "outline/download" });
          })
          .catch((err: any) => {
            this.setState({ loading: false, exportIcon: "outline/download" });
            (this.props as any).onNotify(
              "Error",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          })
      : agent.Gst.getAll(skip, limit, searchText, download, type, tags)
          .then((response: any) => {
            if (notification === true) {
              (this.props as any).onNotify(
                "GSTIN List",
                "Listing of all the GSTIN is successfully",
                "success"
              );
            }
            this.setState({
              displayGstDetails: response.gstin,
              gstDetails: response.gsts,
              loading: false,
              totalRecords: response.count
            });
          })
          .catch((err: any) => {
            this.setState({ loading: false });
            (this.props as any).onNotify(
              "Could not load GST Details",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          });
  };
  //onMount Load data
  componentDidMount() {
    this.getGstData(false, false);
    this.tagListing();
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.chunkSize !== this.state.chunkSize) {
      setTimeout(() => {
        this.getGstData(false, false);
      }, 100);
    }
    /* if (
      prevState.modalOpen !== this.state.modalOpen &&
      this.state.modalOpen === false
    ) {
      setTimeout(() => {
        this.getGstData(false, true);
      }, 100);
    } */
  }

  GSTINUpdate = (gstinArray: any, realtime: boolean) => {
    this.setState({ logging: true });
    let gstin: any = gstinArray;
    agent.Gst.updateGSTIN(gstin)
      .then((response: any) => {
        if (realtime === true) {
          this.setState({ logging: false }, () => {
            this.getGstData(false, false);
          });
        } else {
          this.setState({ logging: false });
          this.setState({ requestId: response.importRequest }, () => {
            this.requestProgress(this.state.requestId);
          });
        }
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).onNotify(
          "Could not get GSTIN List",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  tagListing = () => {
    agent.Tag.getAll()
      .then((response: any) => {
        let finalTagList = response.tags.filter(
          (tag: any) => tag.active === true
        );
        //.map((tag: any) => tag.name);
        this.setState({ tagList: finalTagList });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Error",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  // on page change, load new data from database
  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.getGstData(false, false);
    });
  };

  editGSTName = (gstid: string) => {
    this.setState({
      modalOpen: true,
      selectedGstId: gstid
    });
  };

  handleModalChange = (value: boolean) => {
    this.setState({
      modalOpen: value
    });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
    this.getGstData(false, false);
  };

  handleValueTag = (value: any) => {
    let tagList = this.state.tagList;
    let selectedTags: any = value;
    let selectedTagObject: any = tagList.filter((tag: any) =>
      selectedTags.includes(tag.name)
    );
    let selectedTagId: any = selectedTagObject.map((tag: any) => tag._id);
    this.setState({ selectedTag: selectedTagId });
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: ev.target.value
    });
  };

  handleSearchTextChange = (ev: any) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      searchText: ev.target.value,
      typing: false,
      currPage: 0,
      typingTimeout: setTimeout(() => {
        this.getGstData(false, false);
      }, 700)
    });
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  deleteTransactionInitiate = (requestId: string) => {
    this.setState({ deleteId: requestId });
    this.deleteModalSetOpen(true);
  };

  requestModalSetOpen = (open: boolean) => {
    this.setState({
      showReqeustModal: open
    });
  };

  requestProgress = (requestId: string) => {
    this.setState({ id: requestId });
    this.requestModalSetOpen(true);
  };

  /* extractPreference = (filingFreq: any[], quarter: string) => {
    const prefArray = filingFreq.filter(item => item.quarter === quarter && item.year === this.state.currentYear);
    return prefArray.length === 0 ? "Unavailable" : prefArray[0].preference === "Q" ? "Quarterly" : "Monthly";
  }; */

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        <RequestProgress
          type="UpdateGSTIN"
          onLoad={() => {
            this.getGstData(false, false);
          }}
          requestModalSetOpen={this.requestModalSetOpen}
          state={this.state}
        />
        <div className="gsts">
          <div className="grid grid-cols-2 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="justify-self-start">
              <h1 className=" text-2xl font-semibold text-gray-900">
                All GSTINs
              </h1>
            </div>
            <div className="justify-self-end text-right">
              <Link to={"/gsts/add"}>
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                >
                  <Icon
                    name="outline/plus"
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Add GSTIN
                </button>
              </Link>
            </div>
          </div>

          {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="text-right">
              <Link to={"/gsts/add"}>
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                >
                  Add GSTIN
                </button>
              </Link>
            </div>
          </div> */}

          {/* <EditNameModal
            gstId={this.state.selectedGstId}
            openModal={this.state.modalOpen}
            handleModalChange={this.handleModalChange}
          /> */}

          <DeleteModal
            type="gstin"
            onLoad={() => {
              this.getGstData(false, false);
            }}
            deleteModalSetOpen={this.deleteModalSetOpen}
            state={this.state}
          />

          {!this.state.loading && this.state.displayGstDetails ? (
            this.state.totalRecords || this.state.searchText.length > 0 ? (
              <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
                {/* <div className="flex">
                  <SelectMenu items={years} selected={{ name: this.state.currentYear }} onChange={(year: any) => this.setState({ currentYear: year.name })} />
                </div> */}
                <div className="py-6">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="grid grid-cols-3 basis-2 md:px-8 py-2">
                        <div className="justify-self-start">
                          <button
                            type="button"
                            disabled={this.state.logging}
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                            onClick={() => {
                              this.GSTINUpdate([], false);
                            }}
                          >
                            <Icon
                              name="outline/update"
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            Update GSTIN
                          </button>
                        </div>
                        {/* <div className="justify-self-start">
                          <Link to={"/gsts/add"}>
                            <button
                              type="button"
                              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                            >
                              Add GSTIN
                            </button>
                          </Link>
                        </div> */}
                        <div>
                          <div className="justify-self-stretch items-center">
                            <input
                              id="name"
                              name="name"
                              type="text"
                              placeholder="Search by Legal Name, Trade Name or GSTIN"
                              value={this.state.searchText}
                              onChange={(ev: any) => {
                                this.handleSearchTextChange(ev);
                              }}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="justify-self-end">
                          <button
                            type="button"
                            disabled={this.state.logging}
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                            onClick={() => {
                              this.getGstData(true, true);
                            }}
                          >
                            <Icon
                              name={this.state.exportIcon}
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            Export as XLSX
                          </button>
                        </div>
                      </div>
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider divide-y divide-gray-200"
                                >
                                  Legal Name
                                </th>
                                <th
                                  scope="col"
                                  className="w-3/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Trade Name
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  GST Number
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  GST Type
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Registration Date
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Cancellation Date
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Last Checked
                                </th>
                                <th
                                  scope="col"
                                  className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Action
                                </th>
                                {/* <th scope="col" className="w-4/12 px-4 py-3 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Filing Frequency
                                  <table>
                                    <thead>
                                      <tr>
                                        <th id="Q1" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q1 <br /> Apr-Jun
                                        </th>
                                        <th id="Q2" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q2 <br /> Jul-Sep
                                        </th>
                                        <th id="Q3" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q3
                                          <br /> Oct-Dec
                                        </th>
                                        <th id="Q4" scope="col" className="w-3/12 px-5 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                          Q4
                                          <br /> Jan-Mar
                                        </th>
                                      </tr>
                                    </thead>
                                  </table>
                                </th> */}
                              </tr>
                            </thead>
                            {this.state.totalRecords === 0 ? (
                              <div className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                                No record found matching your search criteria
                              </div>
                            ) : (
                              <tbody>
                                {this.state.displayGstDetails.map(
                                  (gst: any) => (
                                    <tr key={gst.id} className="bg-white">
                                      <td
                                        key={gst.id}
                                        className="w-2/10 px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                      >
                                        {gst.lgnm}
                                        {/* <button
                                      type="button"
                                      onClick={() => this.editGSTName(gst._id)}
                                      className="ml-3 items-center px-1 py-1 border border-transparent text-xs rounded text-black bg-white hover:bg-gray-200 focus:outline-none"
                                    >
                                      <Icon
                                        name="edit"
                                        className="mx-auto flex-shrink-0 h-4 w-4"
                                      />
                                    </button> */}
                                      </td>
                                      <td
                                        key={gst.id}
                                        className="w-2/10 px-6 py-3 whitespace-wrap text-sm text-gray-500"
                                      >
                                        {gst.name}
                                      </td>
                                      <td
                                        key={gst.id}
                                        className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                      >
                                        {gst.gstin}
                                      </td>
                                      <td
                                        key={gst.id}
                                        className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                      >
                                        {gst.gstRegType}
                                      </td>
                                      <td
                                        key={gst.id}
                                        className="w-1/10 px-6 py-3 whitespace-wrap text-sm text-gray-500"
                                      >
                                        {gst.rgdt
                                          ? new Date(
                                              gst.rgdt
                                            ).toLocaleDateString()
                                          : "-"}
                                      </td>
                                      <td
                                        key={gst.id}
                                        className="w-1/10 px-6 py-3 whitespace-wrap text-sm text-gray-500"
                                      >
                                        {gst.cxdt
                                          ? new Date(
                                              gst.cxdt
                                            ).toLocaleDateString()
                                          : "-"}
                                      </td>
                                      <td
                                        key={gst.id}
                                        className="w-2/10 px-6 py-3 whitespace-nowrap text-sm text-gray-500"
                                      >
                                        {gst.lastChecked
                                          ? new Date(
                                              gst.lastChecked
                                            ).toLocaleDateString() +
                                            " - " +
                                            new Date(
                                              gst.lastChecked
                                            ).toLocaleTimeString("en-US", {
                                              hour: "2-digit",
                                              minute: "2-digit"
                                            })
                                          : "-"}
                                      </td>
                                      <td
                                        key={gst.id}
                                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                      >
                                        <button
                                          type="button"
                                          disabled={this.state.logging}
                                          onClick={() =>
                                            this.GSTINUpdate([gst.gstin], true)
                                          }
                                          className="inline-flex items-center mr-2 px-1 py-1 border border-transparent text-xs rounded text-red-700 bg-blue-100 hover:bg-blue-200 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                          <Icon
                                            name="outline/update"
                                            className="mx-auto flex-shrink-0 h-4 w-4"
                                          />
                                        </button>
                                        <button
                                          type="button"
                                          disabled={this.state.logging}
                                          onClick={() =>
                                            this.deleteTransactionInitiate(
                                              gst._id
                                            )
                                          }
                                          className="inline-flex items-center px-1 py-1 border border-transparent text-xs rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                          <Icon
                                            name="delete"
                                            className="mx-auto flex-shrink-0 h-4 w-4"
                                          />
                                        </button>
                                      </td>
                                      {/* <td>
                                    <table>
                                      <tbody>
                                        <tr className="flex w-4/10 pl-4 py-3 whitespace-nowrap text-sm text-gray-500">
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q1")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q2")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q3")}</td>
                                          <td className="w-3/12 px-5 py-3 whitespace-nowrap text-sm text-gray-500">{this.extractPreference(gst.filingFrequency, "Q4")}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td> */}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                <Icon
                  name="outline/document-add"
                  className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                  strokeWidth="1"
                />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No GST Entry
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by adding a new GSTN.
                </p>
                <div className="mt-6">
                  <Link to={"/gsts/add"}>
                    <button
                      type="button"
                      disabled={this.state.logging || this.state.loading}
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      ADD GSTN
                    </button>
                  </Link>
                </div>
              </div>
            )
          ) : (
            <div className={"max-w-7xl mx-auto px-4 sm:px-6 md:px-8"}>
              <div className="py-6">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="overflow-scroll table-auto w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                GST Number
                              </th>
                              <th
                                scope="col"
                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Registered Date
                              </th>
                              <th
                                scope="col"
                                className="w-4/12 px-4 py-3 align-middle text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Filing Frequency
                                <table>
                                  <thead>
                                    <tr>
                                      <th
                                        id="Q1"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q1 <br /> Apr-Jun
                                      </th>
                                      <th
                                        id="Q2"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q2 <br /> Jul-Sep
                                      </th>
                                      <th
                                        id="Q3"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q3
                                        <br /> Oct-Dec
                                      </th>
                                      <th
                                        id="Q4"
                                        scope="col"
                                        className="w-3/12 px-3 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Q4
                                        <br /> Jan-Mar
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {[...Array(4)].map((e, i) => (
                              <tr key={i} className="bg-white">
                                {[...Array(4)].map((e, i) => (
                                  <td
                                    key={i}
                                    className="w-full px-6 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                                  >
                                    <Skeleton />
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.totalRecords > 0 ? (
            <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {this.state.currPage * this.state.chunkSize + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {(this.state.currPage + 1) * this.state.chunkSize >
                      this.state.totalRecords
                        ? this.state.totalRecords
                        : (this.state.currPage + 1) * this.state.chunkSize}
                    </span>{" "}
                    of{" "}
                    <span className="font-medium">
                      {this.state.totalRecords}
                    </span>{" "}
                    results results
                  </p>
                </div>
                <div className="text-sm text-gray-700 flex items-center ml-3 mr-3 gap-2">
                  <div>Items per page</div>
                  <div className="data-dropdown-placement={top}">
                    <SelectMenu
                      items={itemsPerPage}
                      selected={{ name: this.state.chunkSize }}
                      onChange={this.handleItemPerPage}
                    />
                  </div>
                </div>
              </div>
              <div id="pagination" className="text-sm text-gray-500 my-2">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(
                    this.state.totalRecords / this.state.chunkSize
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Dashboard>
    );
  }
}
export default compose(connector, withRouter)(GSTs) as ComponentType;
