import React, { ComponentType } from "react";
import Dashboard from "../../components/Dashboard";
import agent from "../../agent";
import { ADD_NOTIFICATION } from "../../store/types";
import TagManager from "react-gtm-module";
import { connect, ConnectedProps } from "react-redux";
//import { PaperClipIcon } from "@heroicons/react/24/solid";
//import { RadioGroup } from "@headlessui/react";
//import { CheckCircleIcon } from "@heroicons/react/24/solid";
import RadioButton from "../../components/RadioButton";
import { Fragment } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/24/solid";
import { throws } from "assert";
import validGSTIN from "../../helpers/GSTValidationFunction";
import convertNumber from "../../helpers/convertNumber";
import Icon from "../../components/Icon";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";
import { loadScript } from "../../helpers/loadScript";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Subscription"
  },
  dataLayerName: "PageDataLayer"
};

//static features for the available plan. Plans name are required to be changed manually if there is any changes in the plan name.
const sections = [
  {
    name: "Features",
    features: [
      {
        name: "Regular/Composition GSTIN",
        tiers: { "1 Year": true, "3 Year": true, "5 Year": true }
      },
      {
        name: "Returns - GSTR-3B,  GSTR-1,  IFF,  GSTR-9,  GSTR-4,  CMP-08",
        tiers: { "1 Year": true, "3 Year": true, "5 Year": true }
      },
      {
        name: "Unlimited Checks",
        tiers: { "1 Year": true, "3 Year": true, "5 Year": true }
      },
      {
        name: "QRMP Status",
        tiers: { "1 Year": true, "3 Year": true, "5 Year": true }
      },
      {
        name: "Return Status from Jan 2021",
        tiers: { "1 Year": true, "3 Year": true, "5 Year": true }
      }
    ]
  }
];

//Redux mapping

const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & WithRouterProps;

class Subscription extends React.Component<PropsFromRedux> {
  state: {
    loading: boolean;
    currentPlan: string;
    currentPlanId: number;
    GSTCreditPurchased: number;
    unutlizedGSTCredit: number;
    currentExpiry: string; //converting date to string
    activeGSTIN: number;
    purchaseType: string;
    tiers: string[];
    planName: string[];
    selectedPlan: string;
    selectedPlanId: number;
    availablepaytype: [];
    paymentType: string;
    minimumCredits: number;
    credits: number;
    grossAmountExcludingGST: number;
    totalGST: number;
    totalAmountWithGST: number;
    netAmountExcludingGST: number;
    newExpiryDate: string; //converting date to string
    minimumGstinCount: number;
    GSTIN: string;
    legalName: string;
    address: string[];
    newPricePerGSTIN: number;
    daysDifference: number;
    creditAmount: number;
    paymentResponse: string;
    paymentFailureReason: string;
    typing: boolean;
    typingTimeout: number;
  };

  constructor(props: PropsFromRedux) {
    super(props);

    this.state = {
      loading: false,
      currentPlan: "",
      currentPlanId: 0,
      GSTCreditPurchased: 0,
      unutlizedGSTCredit: 0,
      currentExpiry: new Date().toLocaleDateString(),
      activeGSTIN: 0,
      purchaseType: "",
      tiers: [],
      planName: [],
      selectedPlan: "",
      selectedPlanId: 101, //we are sending one so we do not get any error in API. The actual selected plan is setting when the user click on the payment type or select the plan.
      availablepaytype: [],
      paymentType: "",
      minimumCredits: 5,
      credits: 0,
      grossAmountExcludingGST: 0,
      totalGST: 0,
      totalAmountWithGST: 0,
      netAmountExcludingGST: 0,
      newExpiryDate: new Date().toLocaleDateString(),
      minimumGstinCount: 0,
      GSTIN: "",
      legalName: "",
      address: [],
      newPricePerGSTIN: 0,
      daysDifference: 0,
      creditAmount: 0,
      paymentResponse: "",
      paymentFailureReason: "",
      typing: false,
      typingTimeout: 0
    };
  }

  getCurrentPlan = () => {
    this.setState({ loading: true });
    agent.Subscription.currentPlan()
      .then((response: any) => {
        this.setState(
          {
            loading: false,
            currentPlan: response.planName,
            currentPlanId: response.currentPlanId,
            GSTCreditPurchased: response.gstinPurchased,
            unutlizedGSTCredit: response.gstinCreditsBalance,
            currentExpiry: new Date(response.expiryDate).toLocaleDateString(),
            availablepaytype: response.paymentTypesAvailable,
            activeGSTIN: response.minimumGstinCount,
            purchaseType: response.planName === "Free Trial" ? "Upgrade" : "", //We are setting it to upgrade as we do not ask the payment type from user in case of free plan.
            //paymentType: response.planName === "Free Trial" ? "Free" : ""
            paymentType: response.planName === "Free Trial" ? "Upgrade" : "" //Changed to renewal as we do not have any plan type free on 14 Sep 2022. We are setting it to upgrade as we do not ask the payment type from user in case of free plan.
          },
          () => {
            this.getAllPlan();
          }
        );
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load current plan details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getAllPlan = () => {
    this.setState({ loading: true });
    agent.Subscription.allPlan(this.state.purchaseType)
      .then((response: any) => {
        this.setState({
          loading: false,
          tiers: response.availablePlans,
          planName: response.availablePlans.map((plan: any) => plan.name)
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load available plans",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getPayAmount = () => {
    this.setState({ loading: true });
    let planId: number = this.state.selectedPlanId;
    let credits: number = this.state.credits;
    let purchaseType: string = this.state.purchaseType;
    agent.Subscription.payamount(planId, credits, purchaseType)
      .then((response: any) => {
        this.setState({
          loading: false,
          grossAmountExcludingGST: response.grossAmountExcludingGST,
          totalGST: response.totalGST,
          totalAmountWithGST: response.totalAmountWithGST,
          netAmountExcludingGST: response.totalAmount,
          newExpiryDate: response.newExpiryDate,
          minimumGstinCount: response.minimumGstinCount,
          newPricePerGSTIN: response.planPrice,
          daysDifference: response.daysDifference,
          creditAmount: response.creditsLeft,
          GSTIN: response.gstin ? response.gstin : "",
          legalName: response.gstinName
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load payment amount",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getPaymentStatus = () => {
    this.setState({ loading: true });
    let orderid: any = new URLSearchParams(window.location.search).get(
      "orderid"
    );
    orderid !== null
      ? agent.Payment.getPaymentStatus(orderid)
          .then((response: any) => {
            this.setState(
              {
                loading: false,
                paymentResponse: response.order[0].status,
                paymentFailureReason: response.order[0].respmsg
              },
              () => {
                this.state.paymentResponse === "TXN_SUCCESS"
                  ? (this.props as any).onNotify(
                      "Payment Successful",
                      "Thanks for making the payment. Your account has been successfully updated.",
                      "success"
                    )
                  : (this.props as any).onNotify(
                      "Payment Failed",
                      this.state.paymentFailureReason,
                      "danger"
                    );
              }
            );
          })
          .catch((err: any) => {
            this.setState({ loading: false });
            (this.props as any).onNotify(
              "Could not load payment status",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          })
      : this.setState({ loading: false });
  };

  /* getPrice = () => {
    agent.Subscription.allPlan()
      .then((response: any) => {
        this.setState({
          allPlans: response.plans
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Could not load available plans",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };
 */

  /* For PayTM Payment Gateway */

  // handlePayment = () => {
  //   this.setState({ loading: true });
  //   if (this.state.GSTIN !== "" && this.state.legalName === "") {
  //     this.validateGSTINAtPay();
  //     this.setState({ loading: false });
  //   } else {
  //     let amount = this.state.totalAmountWithGST;
  //     let plan = this.state.selectedPlanId;
  //     let GSTIN = this.state.GSTIN;
  //     let legalName = this.state.legalName;
  //     let address = this.state.address;
  //     let paymentType = this.state.paymentType;
  //     let credits = this.state.credits;
  //     let expiryDate = this.state.newExpiryDate;

  //     agent.Payment.process(
  //       amount,
  //       plan,
  //       GSTIN,
  //       legalName,
  //       address,
  //       paymentType,
  //       credits,
  //       expiryDate
  //     )
  //       .then((response: any) => {
  //         this.setState({ loading: false });
  //         var information = {
  //           action: `${process.env.REACT_APP_PAYTM_URL}/theia/api/v1/showPaymentPage?mid=${response.mid}&orderId=${response.orderId}`,
  //           params: response
  //         };
  //         this.post(information);
  //         /* (this.props as any).onNotify(
  //         "GST Return Update Request",
  //         "We have taken your request and will send you an email once the GST return status is updated for all the GSTIN.",
  //         "success"
  //       ); */
  //       })
  //       .catch((err: any) => {
  //         this.setState({ loading: false });
  //         /* (this.props as any).onNotify(
  //         "Error",
  //         err?.response?.data?.message || err?.message || err,
  //         "danger"
  //       ); */
  //       });
  //   }
  // };

  /* For Razorpay Payment Gateway */

  handlePayment = async () => {
    const {
      GSTIN,
      legalName,
      address,
      totalAmountWithGST: amount,
      selectedPlanId,
      paymentType,
      credits,
      newExpiryDate
    } = this.state;

    if (this.state.GSTIN) {
      const isValidGSTIN = validGSTIN(this.state.GSTIN);

      if (!isValidGSTIN) return;
    }

    this.setState({ loading: true });

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      (this.props as any).onNotify(
        "Error",
        "Razorpay SDK failed to load!",
        "danger"
      );
      return;
    }

    agent.Payment.process(
      amount,
      selectedPlanId,
      GSTIN,
      legalName,
      address,
      paymentType,
      credits,
      newExpiryDate
    )
      .then((result: any) => {
        const { amount, orderId } = result;

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount,
          currency: "INR",
          name: "TaxPido",
          description: `Payment for ${paymentType}`,
          order_id: orderId,
          prefill: {
            email: (this.props as any).email || "",
            contact: (this.props as any).mobile || ""
          },
          readonly: { email: true },
          notes: { appName: "RSC" },
          handler: async (response: any) => {
            const data = {
              orderId,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature
            };

            agent.Payment.updateOrder(data)
              .then((res: any) => {
                if (res.success) {
                  (this.props as any).onNotify(
                    "Payment Captured",
                    "Payment has been successfully captured",
                    "success"
                  );

                  (this.props as any).navigate(
                    `/paymentresponse?orderid=${orderId}`
                  );
                }
              })
              .catch((err: any) => {
                console.log(err);
              });
          },
          modal: { confirm_close: true }
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((err: any) => {
        console.log(err);

        (this.props as any).onNotify(
          "Error while processing payment",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.getCurrentPlan();
    this.getPaymentStatus();
    //this.getAllPlan();
  }

  isDate(val: any) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  isObj(val: any) {
    return typeof val === "object";
  }

  stringifyValue(val: any) {
    if (this.isObj(val) && !this.isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  /* 
  buildForm({ action, params }: any) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach(key => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", this.stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }

  post(details: any) {
    const form = this.buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }
  
 */

  handlePlanSelection = (value: any) => {
    this.setState(
      {
        selectedPlan: value,
        /* paymentType:
          this.state.currentPlan === "Free Trial" ? "Upgrade" : value, */
        credits: Math.max(this.state.activeGSTIN, this.state.minimumCredits)
      },
      () => {
        this.getPayAmount();
      }
    );
  };

  handlePlanId = (value: any) => {
    this.setState({
      selectedPlanId: value
    });
  };

  handlePaymentType = (value: any) => {
    this.setState({ loading: true });
    value === "Extra GST Credits"
      ? this.setState(
          {
            loading: false,
            purchaseType: value,
            paymentType: value,
            selectedPlan: this.state.currentPlan,
            selectedPlanId: this.state.currentPlanId,
            minimumCredits: 5,
            credits: 5
          },
          () => {
            this.getPayAmount();
          }
        )
      : this.setState(
          {
            loading: false,
            paymentType:
              this.state.currentPlan === "Free Trial" ? "Upgrade" : value,
            purchaseType:
              this.state.currentPlan === "Free Trial" ? "Upgrade" : value,
            selectedPlan: "",
            minimumCredits: this.state.activeGSTIN,
            credits: this.state.activeGSTIN
          },
          () => {
            this.getAllPlan();
          }
        );
  };

  handleCredits = (ev: any) => {
    let min: any = 0;
    let max: any = 10000;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      credits: Math.max(min, Math.min(max, ev.target.value)),
      typingTimeout: setTimeout(() => {
        this.getPayAmount();
      }, 700)
    });
  };

  handleGSTIN = (ev: any) => {
    this.setState({ GSTIN: ev.target.value }, () => {
      this.validateGSTIN();
    });
  };

  validateGSTIN = () => {
    this.setState({ loading: true });
    if (this.state.GSTIN.length === 15) {
      let isValid = validGSTIN(this.state.GSTIN);
      if (isValid) {
        this.setState({ loading: true });
        agent.Subscription.gstinDetails(this.state.GSTIN)
          .then((response: any) => {
            this.setState({
              loading: false,
              legalName:
                response.gstDetails.name === ""
                  ? response.gstDetails.lgnm
                  : response.gstDetails.name,
              address: response.gstDetails.address
            });
            /* (this.props as any).onNotify(
              "Added GSTIN",
              "Successfully added new GSTIN.",
              "success"
            ); */
          })
          .catch((err: any) => {
            this.setState({ loading: false });
            (this.props as any).onNotify(
              "Could not get GSTIN Data",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          });
      } else {
        (this.props as any).onNotify(
          "Incorrect GSTIN",
          "Please enter a valid GSTIN to proceed.",
          "danger"
        );
      }
    } else {
      this.setState({
        loading: false,
        legalName: "",
        address: ""
      });
    }
  };

  validateGSTINAtPay = () => {
    let isValid = validGSTIN(this.state.GSTIN);
    if (isValid) {
      this.setState({ loading: true });
      agent.Subscription.gstinDetails(this.state.GSTIN)
        .then((response: any) => {
          this.setState({
            loading: false,
            legalName:
              response.gstDetails.name === ""
                ? response.gstDetails.lgnm
                : response.gstDetails.name,
            address: response.gstDetails.address
          });
          /* (this.props as any).onNotify(
              "Added GSTIN",
              "Successfully added new GSTIN.",
              "success"
            ); */
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          (this.props as any).onNotify(
            "Could not get GSTIN Data",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
    } else {
      (this.props as any).onNotify(
        "Incorrect GSTIN",
        "Please enter a valid GSTIN to proceed.",
        "danger"
      );
    }
  };

  classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <Dashboard>
        <div className="gsts">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              Subscription Details
            </h1>

            {/* <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
          onClick={this.handlePayment}
        >
          Pay via PayTM
        </button> */}
            <div className="pt-8 pb-2">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-5">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Current Subscription
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Shows your current subscriptions
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Current Plan
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {this.state.currentPlan}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        GST Credits Purchased
                      </dt>

                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {this.state.GSTCreditPurchased}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Active GSTIN
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {this.state.activeGSTIN}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Unutlized GSTIN Credits
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {this.state.unutlizedGSTCredit}
                      </dd>
                    </div>
                    {this.state.GSTCreditPurchased -
                      this.state.activeGSTIN -
                      this.state.unutlizedGSTCredit >
                    0 ? (
                      <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Deleted GSTIN
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {this.state.GSTCreditPurchased -
                            this.state.activeGSTIN -
                            this.state.unutlizedGSTCredit}
                        </dd>
                      </div>
                    ) : null}
                    <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Plan Expiry Date
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {this.state.currentExpiry}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className="pt-8 pb-2">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-5">
                {/* option to show the payment type except user have free plan*/}
                {this.state.currentPlan !== "Free Trial" ? (
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Payment Type
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Select type of payment which you want to do
                    </p>
                    <RadioButton
                      list={this.state.availablepaytype}
                      handleValue={this.handlePaymentType}
                    ></RadioButton>
                  </div>
                ) : (
                  ""
                )}

                <div>
                  {/* option to show the plan table on the basis of the payment type selected by the user */}
                  {this.state.paymentType === "" ? (
                    ""
                  ) : this.state.paymentType === "Extra GST Credits" ? (
                    ""
                  ) : (
                    <div className="bg-white">
                      <div className="max-w-7xl mx-auto bg-white py-14 sm:py-18 sm:px-6 lg:px-8">
                        {/* xs to lg */}
                        <div className="max-w-2xl mx-auto space-y-16 lg:hidden">
                          {this.state.tiers.map((tier: any, tierIdx: any) => (
                            <section key={tier.name}>
                              <div className="px-4 mb-8">
                                <h2 className="text-lg leading-6 font-medium text-gray-900">
                                  {tier.name}
                                </h2>
                                <p className="mt-4">
                                  <span className="text-4xl font-extrabold text-gray-900">
                                    Rs {tier.price}
                                  </span>{" "}
                                  <span className="text-base font-medium text-gray-500">
                                    /GSTIN
                                  </span>
                                </p>
                                <p className="mt-4 text-sm text-gray-500">
                                  {tier.description}
                                </p>
                                <a
                                  href={tier.href}
                                  className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                                >
                                  Buy {tier.name}
                                </a>
                              </div>
                              {sections.map((section: any) => (
                                <table key={section.name} className="w-full">
                                  <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                                    {section.name}
                                  </caption>
                                  <thead>
                                    <tr>
                                      <th className="sr-only" scope="col">
                                        Feature
                                      </th>
                                      <th className="sr-only" scope="col">
                                        Included
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {section.features.map((feature: any) => (
                                      <tr
                                        key={feature.name}
                                        className="border-t border-gray-200"
                                      >
                                        <th
                                          className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
                                          scope="row"
                                        >
                                          {feature.name}
                                        </th>
                                        <td className="py-5 pr-4">
                                          {typeof feature.tiers[tier.name] ===
                                          "string" ? (
                                            <span className="block text-sm text-gray-700 text-right">
                                              {feature.tiers[tier.name]}
                                            </span>
                                          ) : (
                                            <>
                                              {feature.tiers[tier.name] ===
                                              true ? (
                                                <CheckIcon
                                                  className="ml-auto h-5 w-5 text-green-500"
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <MinusIcon
                                                  className="ml-auto h-5 w-5 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                              )}

                                              <span className="sr-only">
                                                {feature.tiers[tier.name] ===
                                                true
                                                  ? "Yes"
                                                  : "No"}
                                              </span>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              ))}

                              <div
                                className={this.classNames(
                                  tierIdx < this.state.tiers.length - 1
                                    ? "py-5 border-b"
                                    : "pt-5",
                                  "border-t border-gray-200 px-4"
                                )}
                              >
                                <a
                                  href={tier.href}
                                  className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                                >
                                  Buy {tier.name}
                                </a>
                              </div>
                            </section>
                          ))}
                        </div>
                        {/* lg+ */}
                        <div className="hidden lg:block">
                          <table className="w-full h-px table-fixed">
                            <caption className="sr-only">
                              Pricing plan comparison
                            </caption>
                            <thead>
                              <tr>
                                <th
                                  className="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
                                  scope="col"
                                >
                                  <span className="sr-only">Feature by</span>
                                  <span>Plans</span>
                                </th>
                                {this.state.tiers.map((tier: any) => (
                                  <th
                                    key={tier.name}
                                    className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                                    scope="col"
                                  >
                                    {tier.name}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="border-t border-gray-200 divide-y divide-gray-200">
                              <tr>
                                <th
                                  className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
                                  scope="row"
                                >
                                  Pricing
                                </th>
                                {this.state.tiers.map((tier: any) => (
                                  <td
                                    key={tier.name}
                                    className="h-full py-8 px-6 align-top"
                                  >
                                    <div className="relative h-full table">
                                      <p>
                                        <span className="text-4xl font-extrabold text-gray-900">
                                          Rs {tier.price}
                                        </span>{" "}
                                        <span className="text-base font-medium text-gray-500">
                                          /GSTIN
                                        </span>
                                      </p>
                                      {/* <p className="mt-4 mb-16 text-sm text-gray-500">
                                      {tier.description}
                                    </p>
                                    <a
                                      href={tier.href}
                                      className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                                    >
                                      Buy {tier.name}
                                    </a> */}
                                    </div>
                                  </td>
                                ))}
                              </tr>
                              {sections.map((section: any) => (
                                <Fragment key={section.name}>
                                  <tr>
                                    <th
                                      className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                                      colSpan={4}
                                      scope="colgroup"
                                    >
                                      {section.name}
                                    </th>
                                  </tr>
                                  {section.features.map((feature: any) => (
                                    <tr key={feature.name}>
                                      <th
                                        className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                                        scope="row"
                                      >
                                        {feature.name}
                                      </th>
                                      {this.state.tiers.map((tier: any) => (
                                        <td
                                          key={tier.name}
                                          className="py-5 px-6"
                                        >
                                          {typeof feature.tiers[tier.name] ===
                                          "string" ? (
                                            <span className="block text-sm text-gray-700">
                                              {feature.tiers[tier.name]}
                                            </span>
                                          ) : (
                                            <>
                                              {feature.tiers[tier.name] ===
                                              true ? (
                                                <CheckIcon
                                                  className="h-5 w-5 text-green-500"
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <MinusIcon
                                                  className="h-5 w-5 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                              )}

                                              <span className="sr-only">
                                                {feature.tiers[tier.name] ===
                                                true
                                                  ? "Included"
                                                  : "Not included"}{" "}
                                                in {tier.name}
                                              </span>
                                            </>
                                          )}
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                                </Fragment>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr className="border-t border-gray-200">
                                <th className="sr-only" scope="row">
                                  Choose your plan
                                </th>

                                {this.state.tiers.map((tier: any) => (
                                  <td key={tier.name} className="pt-5 px-6">
                                    <button
                                      className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                                      onClick={() => {
                                        this.handlePlanId(tier._id);
                                        this.handlePlanSelection(tier.name);
                                      }}
                                    >
                                      Buy {tier.name}
                                    </button>
                                  </td>
                                ))}
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    {/* Logic for showing the purchase details */}
                    {this.state.paymentType === "" ||
                    this.state.selectedPlan === "" ? (
                      ""
                    ) : (
                      <div className="px-4 py-4 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 py-4">
                          Purchase Details
                        </h3>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Payment For
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {this.state.paymentType}
                              </dd>
                            </div>
                            <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                Selected Plan
                              </dt>

                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {this.state.selectedPlan}
                              </dd>
                            </div>
                            <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                New Expiry Date
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {new Date(
                                  this.state.newExpiryDate
                                ).toLocaleDateString()}
                                {this.state.paymentType ===
                                  "Extra GST Credits" &&
                                this.state.currentPlan === "1 Year"
                                  ? " (Your current plan will be renewed for a period of one year)"
                                  : ""}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    )}

                    <div className="px-4 py-4 sm:px-6">
                      {/* Logic for showing the payment calculation heading */}
                      {this.state.paymentType === "" ||
                      this.state.selectedPlan === "" ? (
                        ""
                      ) : (
                        <h3 className="text-lg leading-6 font-medium text-gray-900 py-4">
                          Payment Calculation
                        </h3>
                      )}
                      <div>
                        {/* Logic for showing the plan charges */}
                        {this.state.paymentType === "" ||
                        this.state.selectedPlan === "" ? (
                          ""
                        ) : (
                          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-semibold text-gray-700">
                                  Calculation of Plan Charges
                                </dt>
                              </div>
                              <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Price Per GSTIN Credit
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  Rs{" "}
                                  {convertNumber(this.state.newPricePerGSTIN)}
                                </dd>
                              </div>
                              <div className="py-4 sm:py-2 sm:grid sm:grid-cols-9 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500 sm:col-span-3">
                                  Number of GSTIN credits to be purchased
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                                  <div key={this.state.paymentType}>
                                    <input
                                      id="number"
                                      name="number"
                                      type="number"
                                      max={10000}
                                      onChange={this.handleCredits}
                                      value={this.state.credits}
                                      required
                                      className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </dd>
                                <dd className="text-sm inline-block font-medium text-gray-500 sm:col-span-4">
                                  Minimum GST Credits to be purchased is{" "}
                                  {this.state.paymentType ===
                                  "Extra GST Credits"
                                    ? 5
                                    : Math.max(this.state.activeGSTIN, 5)}
                                </dd>
                              </div>
                              {this.state.paymentType === "Extra GST Credits" &&
                              this.state.currentPlan === "1 Year" ? (
                                <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Total GSTIN Credits
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {this.state.activeGSTIN +
                                      this.state.unutlizedGSTCredit +
                                      this.state.credits}{" "}
                                    (Active GSTIN + Unutilized GSTIN Credit+ New
                                    Extra GSTIN Credits)
                                  </dd>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Plan Charges
                                  {this.state.paymentType === "Renewal" ||
                                  (this.state.paymentType ===
                                    "Extra GST Credits" &&
                                    this.state.currentPlan !== "1 Year")
                                    ? ""
                                    : " (A)"}
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  Rs{" "}
                                  {convertNumber(
                                    this.state.grossAmountExcludingGST
                                  )}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        )}

                        <div className="px-4 py-5 sm:p-0">
                          {/* Logic for showing the proportionate credit */}
                          {this.state.paymentType === "" ||
                          this.state.selectedPlan === "" ||
                          this.state.currentPlan === "Free Trial" ? (
                            ""
                          ) : this.state.paymentType === "Renewal" ? (
                            ""
                          ) : this.state.paymentType === "Extra GST Credits" &&
                            this.state.currentPlan !== "1 Year" ? (
                            ""
                          ) : (
                            <dl className="border-t border-gray-200 sm:divide-y sm:divide-gray-200">
                              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-semibold text-gray-700 sm:col-span-2">
                                  Calculation of Proportionate Amount Deducted
                                </dt>
                              </div>
                              <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Current Plan Expiry Date
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  {this.state.currentExpiry}
                                </dd>
                              </div>
                              <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Remaining Days
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  {this.state.daysDifference}
                                </dd>
                              </div>
                              <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Proportionate Amount Deductible (B)
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  Rs {convertNumber(this.state.creditAmount)}
                                </dd>
                              </div>
                            </dl>
                          )}
                          {/* Logic for showing the payable amount */}
                          {this.state.paymentType === "" ||
                          this.state.selectedPlan === "" ? (
                            ""
                          ) : (
                            <dl className="sm:divide-y sm:divide-gray-200">
                              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-semibold text-gray-700">
                                  Calculation of Payable Amount
                                </dt>
                              </div>
                              <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  {this.state.paymentType === "Renewal" ||
                                  (this.state.paymentType ===
                                    "Extra GST Credits" &&
                                    this.state.currentPlan !== "1 Year")
                                    ? "Plan Charges"
                                    : " Net Plan Charges(A-B)"}
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  Rs{" "}
                                  {convertNumber(
                                    this.state.netAmountExcludingGST
                                  )}
                                </dd>
                              </div>
                              {/* <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Add: 18% GST
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4">
                                  Rs {convertNumber(this.state.totalGST)}
                                  <div className="text-sm text-gray-900 sm:col-span-2">
                                    <input
                                      id="text"
                                      name="text"
                                      type="text"
                                      maxLength={15}
                                      placeholder="Enter your GSTIN"
                                      onChange={this.handleGSTIN}
                                      value={String(this.state.GSTIN)}
                                      className="h-5 appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-xs"
                                    />
                                  </div>
                                </dd>
                                <div className="text-sm font-semibold text-gray-900 sm:col-span-1">
                                  {this.state.legalName}
                                </div>
                              </div> */}

                              {/* the state.totalAmountWithGST value is same as the state.totalAmount because the GST is not added to the total amount in the backend */}

                              <div className="py-4 sm:py-2 sm:grid sm:grid-cols-9 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500 sm:col-span-3">
                                  Total Amount
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                                  Rs{" "}
                                  {convertNumber(this.state.totalAmountWithGST)}
                                </dd>
                              </div>
                              <div className="py-8 sm:py-8">
                                <button
                                  disabled={
                                    this.state.loading === true
                                      ? true
                                      : this.state.credits >=
                                        this.state.minimumCredits
                                      ? this.state.totalAmountWithGST > 0
                                        ? false
                                        : true
                                      : true
                                  }
                                  type="submit"
                                  className={
                                    this.state.credits >=
                                    this.state.minimumCredits
                                      ? this.state.totalAmountWithGST > 0
                                        ? "inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                                        : "inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-300 cursor-not-allowed"
                                      : "inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-300 cursor-not-allowed"
                                  }
                                  onClick={this.handlePayment}
                                >
                                  {this.state.loading ? (
                                    <Icon name="loading" />
                                  ) : null}
                                  <Icon
                                    name="outline/rupee"
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  Make Payment
                                </button>
                                {this.state.totalAmountWithGST > 0 ? (
                                  ""
                                ) : (
                                  <dd className="text-sm inline-block font-medium text-gray-500 sm:col-span-4">
                                    &nbsp;&nbsp;(Payment not allowed as your
                                    total amount is negative)
                                  </dd>
                                )}
                              </div>

                              <div className="text-sm pt-4 space-y-3 text-red-500">
                                <p>
                                  <span className="font-bold">Note: -</span>{" "}
                                  From 07 Sep 2024, we will add extra GSTIN
                                  credit for the{" "}
                                  <b>10% of the total payment made</b> to us
                                  once the payment is successful (on each and
                                  every payment). This 10% additional credit is
                                  to cover the various scenario like GSTIN
                                  surrender, client lefts etc because we do not
                                  add back GSTIN credit once you delete any
                                  GSTIN.
                                </p>
                                <p>
                                  In case of renewal, we suggest you to remove
                                  the non required GSTIN before renewal of plan.
                                </p>
                              </div>
                            </dl>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}
export default compose(connector, withRouter)(Subscription) as ComponentType;
