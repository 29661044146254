export async function loadScript(src: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(src);
    };
    script.onerror = err => {
      reject(err);
    };
    document.body.appendChild(script);
  });
}
