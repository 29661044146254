const quarterTillCurrentQuarter = () => {
  const currentQuarter = getCurrentQuarter();
  let startingQuarter: string = "Jan 21 to Mar 21";
  let quarters: string[] = ["Jan 21 to Mar 21"];

  for (let i = 0; i < 1000; i++) {
    const nextQuar: any = nextQuarter(startingQuarter);
    quarters.push(nextQuar);

    if (nextQuar === currentQuarter) {
      break;
    }
    startingQuarter = nextQuar;
  }

  return quarters.reverse();
};
quarterTillCurrentQuarter();

function nextQuarter(quarter: string) {
  const parts = quarter.split(" ");
  let nextq: string;
  if (parts[0] === "Jan") {
    nextq = `Apr ${parts[1]} to Jun ${parts[1]}`;
    return nextq;
  }
  if (parts[0] === "Apr") {
    nextq = `Jul ${parts[1]} to Sep ${parts[1]}`;
    return nextq;
  }
  if (parts[0] === "Jul") {
    nextq = `Oct ${parts[1]} to Dec ${parts[1]}`;
    return nextq;
  }
  if (parts[0] === "Oct") {
    nextq = `Jan ${parseInt(parts[1]) + 1} to Mar ${parseInt(parts[1]) + 1}`;
    return nextq;
  }
}
function getCurrentQuarter() {
  const todayDate3 = new Date();
  todayDate3.setMonth(todayDate3.getMonth());
  const currentYear: any = todayDate3.getFullYear() - 2000;
  const month = todayDate3.toLocaleString("default", { month: "long" });
  if (month === "January" || month === "February" || month === "March") {
    return `Jan ${currentYear} to Mar ${currentYear}`;
  }
  if (month === "April" || month === "May" || month === "June") {
    return `Apr ${currentYear} to Jun ${currentYear}`;
  }
  if (month === "July" || month === "August" || month === "September") {
    return `Jul ${currentYear} to Sep ${currentYear}`;
  }
  if (month === "October" || month === "November" || month === "December") {
    return `Oct ${currentYear} to Dec ${currentYear}`;
  }
}

export default quarterTillCurrentQuarter;
