import { UPDATE_COMMON } from "../types";

const initialState = {
  bulkCopyDone: false,
  showMaintenance: false,
  maintenanceRunningMessage: "",
  upcomingMaintenanceMessage: ""
};

export type CommonState = typeof initialState;

const commonReducer = (
  state = initialState,
  action: {
    type: typeof UPDATE_COMMON;
    payload: Partial<typeof initialState>;
  }
) => {
  switch (action.type) {
    case UPDATE_COMMON:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
export default commonReducer;
