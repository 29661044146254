import React, { ComponentType } from "react";
//Redux
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
// Routing
import routes from "../../constants/routes";
import { ADD_NOTIFICATION } from "../../store/types";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification
});

const mapDispatchToProps = (dispatch: any) => ({
  onVerify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & WithRouterProps
;

//Main Class
class SignupToken extends React.Component<PropsFromRedux> {
  state: { logging: boolean };

  constructor(props: PropsFromRedux) {
    super(props);

    this.state = {
      logging: false
    };
  }

  verify = () => {
    this.setState({ logging: true });
    const { token } = this.props.params;
    token &&
      agent.Auth.verifySignupToken(token)
        .then((response: any) => {
          this.setState({ logging: false });
          this.props.navigate?.(routes.AgencyClients);
          (this.props as any).onVerify(
            "Successful",
            "Successfully signed up. Please Login to continue.",
            "success"
          );
        })
        .catch((err: any) => {
          this.setState({ logging: false });
          (this.props as any).onVerify(
            "Failed!",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
  };

  componentDidMount() {
    this.verify();
  }

  render() {
    return <div></div>;
  }
}

export default compose(connector, withRouter)(SignupToken) as ComponentType;
