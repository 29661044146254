import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import agent from "../agent";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../store/types";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { WithRouterProps } from "../helpers/withRouter";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

type Props = {
  onLoad?: () => void;
  deleteModalSetOpen?: (open: boolean) => void;
  state?: any;
  type?: any;
} & Partial<WithRouterProps>;

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class DeleteModal extends React.Component<Props> {
  state: {
    logging: boolean;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      logging: false
    };
  }

  deleteIdRow = () => {
    if ((this.props as any).state.deleteId) {
      this.setState({
        logging: true
      });
      return this.props.type === "purchaseImport"
        ? agent.Purchase.deleteImportData((this.props as any).state.deleteId)
            .then(() => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Success!",
                "Deleted Import Request Successfully.",
                "success"
              );
              this.setOpen(false);
              this.onLoad();
            })
            .catch((err: any) => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
              this.setOpen(false);
            })
        : this.props.type === "purchaseTransactions"
        ? agent.Purchase.deleteTransaction((this.props as any).state.deleteId)
            .then(() => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Success!",
                "Deleted Transaction Successfully.",
                "success"
              );
              this.setOpen(false);
              this.onLoad();
            })
            .catch((err: any) => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
              this.setOpen(false);
            })
        : this.props.type === "salesImport"
        ? agent.Sales.deleteImportData((this.props as any).state.deleteId)
            .then(() => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Success!",
                "Deleted Import Request Successfully.",
                "success"
              );
              this.setOpen(false);
              this.onLoad();
            })
            .catch((err: any) => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
              this.setOpen(false);
            })
        : this.props.type === "salesTransactions"
        ? agent.Sales.deleteTransaction((this.props as any).state.deleteId)
            .then(() => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Success!",
                "Deleted Transaction Successfully.",
                "success"
              );
              this.setOpen(false);
              this.onLoad();
            })
            .catch((err: any) => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
              this.setOpen(false);
            })
        : this.props.type === "gstin"
        ? agent.Gst.deleteTransaction((this.props as any).state.deleteId)
            .then(() => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Success!",
                "GSTIN deleted Successfully.",
                "success"
              );
              this.setOpen(false);
              this.onLoad();
            })
            .catch((err: any) => {
              this.setState({
                logging: false
              });
              (this.props as any).addNotification(
                "Error",
                err?.response?.data?.message || err?.message || err,
                "danger"
              );
              this.setOpen(false);
            })
        : this.setState({
            logging: false
          });
    } else {
      return this.setOpen(false);
    }
  };

  setOpen = (open: boolean) => {
    (this.props as any).deleteModalSetOpen(open);
  };

  onLoad = () => {
    (this.props as any).onLoad();
  };

  render() {
    return (
      <>
        <Transition.Root
          show={(this.props as any).state.showDeleteModal}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => this.setOpen(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => this.setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Confirm Delete
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {this.props.type.endsWith("Import")
                            ? "File will be deleted permanently and cannot be accessed again. Are you sure you want to delete this file?"
                            : this.props.type.endsWith("gstin")
                            ? "Are you sure you want to delete this GSTIN? This cannot be undone.Please note that the GSTIN credit will not be reveresed."
                            : "Are you sure you want to delete this row? This cannot be undone."}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => this.deleteIdRow()}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => this.setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(DeleteModal);
