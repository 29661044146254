import { Component, MutableRefObject, createRef, ComponentType } from "react";
import axios from "axios";
import agent from "../agent";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store";
import { CommonState } from "../store/reducers/common";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Icon from "./Icon";

const mapStateToProps = (state: RootState) => ({
  ...state.user,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: Partial<CommonState>) =>
    dispatch({ type: "UPDATE_COMMON", payload })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = Partial<PropsFromRedux> & {
  showInfoFooter?: boolean;
  showHideInfoFooter?: () => void;
  infoFooterHeight?: number;
};

interface MaintenanceData {
  frontendVersion: number;
  maintenance: boolean;
  ipList: string[];
  maintenanceRunningMessage: string;
  upcomingMaintenanceMessage: string;
}

interface State {
  showMaintenaceMessage: boolean;
  showRefreshMessage: boolean;
  frontendVersion?: number;
  ipList: string[];
}

class MessageBar extends Component<Props, State> {
  state: State = {
    showMaintenaceMessage: false,
    showRefreshMessage: false,
    ipList: []
  };

  getMaintenanceStatusRef: MutableRefObject<NodeJS.Timeout | null> =
    createRef();

  getFrontEndVersionIntervalRef: MutableRefObject<NodeJS.Timeout | null> =
    createRef();

  toggleInfoButtonRef = createRef<HTMLDivElement>();

  componentDidMount() {
    localStorage.getItem("frontendJSVersion") &&
      this.setState({
        frontendVersion: JSON.parse(
          localStorage.getItem("frontendJSVersion") as string
        )
      });

    this.getMaintenanceStatusAndFrontendVersion();
    this.getMaintenanceStatusRef.current = setInterval(() => {
      this.getMaintenanceStatusAndFrontendVersion();
    }, 1000 * 60 * 10); // update every 10 minutes
  }

  componentWillUnmount() {
    if (this.getMaintenanceStatusRef.current) {
      clearInterval(this.getMaintenanceStatusRef.current);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.frontendVersion !== this.state.frontendVersion &&
      !prevState.frontendVersion &&
      this.state.frontendVersion
    ) {
      localStorage.setItem(
        "frontendJSVersion",
        JSON.stringify(this.state.frontendVersion)
      );
    }
    if (
      prevState.frontendVersion !== this.state.frontendVersion &&
      prevState.frontendVersion &&
      this.state.frontendVersion &&
      prevState?.frontendVersion < this.state?.frontendVersion
    ) {
      this.setState({ showRefreshMessage: true });
    }
  }

  getMaintenanceStatusAndFrontendVersion = () => {
    agent.common
      .maintenanceStatus()
      .then((res: MaintenanceData) => {
        const {
          frontendVersion,
          maintenance,
          upcomingMaintenanceMessage,
          maintenanceRunningMessage,
          ipList
        } = res;

        this.props.updateCommon?.({
          showMaintenance: maintenance,
          maintenanceRunningMessage,
          upcomingMaintenanceMessage
        });

        this.setState(
          {
            frontendVersion,
            showMaintenaceMessage: !!upcomingMaintenanceMessage,
            showRefreshMessage:
              localStorage.getItem("frontendJSVersion") !==
              JSON.stringify(frontendVersion),
            ipList
          },
          () => {
            maintenance && this.getIp();
          }
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  getIp = () => {
    axios
      .get<{ ip: string }>("https://api.ipify.org/?format=json")
      .then(res => this.changeMaintenanceStatus(res.data.ip))
      .catch(err => {
        // console.log(err);
      });
  };

  changeMaintenanceStatus = (userIp: string) => {
    const isUserIpAllowed = this.state.ipList?.includes(userIp);
    const showMaintenance = this.state.showMaintenaceMessage && isUserIpAllowed;

    this.props.updateCommon?.({ showMaintenance: !showMaintenance });
  };

  refreshPage() {
    window.location.reload();
    localStorage.setItem(
      "frontendJSVersion",
      JSON.stringify(this.state.frontendVersion)
    );
  }

  render() {
    const { showInfoFooter, showHideInfoFooter, infoFooterHeight } = this.props;
    const { showMaintenaceMessage, showRefreshMessage } = this.state;

    return (
      <>
        {(showMaintenaceMessage || showRefreshMessage) && (
          <div
            ref={this.toggleInfoButtonRef}
            style={{
              bottom: showInfoFooter
                ? `calc(${infoFooterHeight}px +  ${
                    showRefreshMessage && showMaintenaceMessage
                      ? "8rem"
                      : showRefreshMessage
                      ? "4rem"
                      : "3rem"
                  })`
                : "0"
            }}
            className="md:hidden absolute z-0 left-6 border-2 border-red-500 rounded-t-md bg-red-400 cursor-pointer transition-[bottom] duration-300 ease-in-out"
            onClick={showHideInfoFooter}
          >
            <ChevronDownIcon
              className={`h-8 w-8 transition-transform duration-300 ease-in-out ${
                showInfoFooter ? "" : "rotate-180"
              }`}
            />
          </div>
        )}
        <div
          className={`space-y-3 divide-y fixed bottom-0 inset-x-0 z-10 inline-block w-full text-center bg-red-400 text-white text-sm font-bold transition-transform origin-bottom duration-300 ease-in-out ${
            showInfoFooter ? "" : "scale-y-0"
          }`}
        >
          {showRefreshMessage && (
            <button
              onClick={() => this.refreshPage()}
              className={`pt-2 ${
                showRefreshMessage && !showMaintenaceMessage ? "pb-2" : ""
              }`}
            >
              <p>
                The Content of the page has been updated. Please refresh the
                page to see the latest content.
                <span className="bg-white text-black font-medium ml-10 my-1 rounded-lg px-2 py-1 inline-flex gap-2 items-center">
                  Refresh
                  <Icon name="refresh" className="h-4 w-4 text-inherit" />
                </span>
              </p>
            </button>
          )}
          {showMaintenaceMessage && (
            <p className="py-2">{this.props.upcomingMaintenanceMessage}</p>
          )}
        </div>
      </>
    );
  }
}

export default connector<ComponentType<Props>>(MessageBar);
