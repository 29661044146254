import axios from "axios";
//import { request } from "https";
import config from "./config";

axios.defaults.baseURL = config.baseURL;

let token: string | undefined;

const responseBody = (res: any) => res.data;

const getHeader = () => {
  if (!token) {
    return {};
  }

  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

const requests = {
  get: (url: string) => axios.get(url, getHeader()).then(responseBody),
  getBlob: (url: string, options: any) =>
    axios.get(url, { ...options, ...getHeader() }),
  post: (url: string, body: any) =>
    axios.post(url, body, getHeader()).then(responseBody),
  put: (url: string, body: any) =>
    axios.put(url, body, getHeader()).then(responseBody),
  patch: (url: string, body: any) =>
    axios.patch(url, body, getHeader()).then(responseBody),
  delete: (url: string) => axios.delete(url, getHeader()).then(responseBody)
};

const Agency = {
  Client: {
    list: () => requests.get("/gsts")
  }
};

const Auth = {
  //Login
  login: (email: string, password: string) =>
    requests.post("/auth/login", { email, password }),
  loginOTPRequest: (email: string) =>
    requests.post("/auth/otpLogin", { email }),
  loginViaOTP: (email: string, otp: number) =>
    requests.post("/auth/verify/loginOtp", { email, otp }),

  //Signup
  signup: (
    name: string,
    email: string,
    password: string,
    userType: string,
    mobile: number,
    source: string,
    clickId: string
  ) =>
    requests.post("/auth/signup", {
      name,
      email,
      password,
      userType,
      mobile,
      source,
      clickId
    }),
  verifySignupOTP: (userId: string, otp: number) =>
    requests.post("/auth/verify/signup/otp", { userId, otp }),
  verifySignupToken: (token: string) =>
    requests.post("/auth/verify/signup/token", { token }),
  resendOTP: (userId: string) => requests.post("/auth/resendotp", { userId }),
  //Forgot Password
  forgotPassword: (email: string) =>
    requests.post("/auth/forgotPassword", { email }),
  verifyResetOtp: (userId: string, otp: number, password: string) =>
    requests.post("/auth/verify/reset/otp", { userId, otp, password }),
  verifyResetToken: (token: string, password: string) =>
    requests.post("/auth/verify/reset/token", { token, password }),
  updateUser: (source: string, clickId: string) =>
    requests.post("/auth/updateuser", { source, clickId }),
  getUserDetails: () => requests.get("/auth/getUserDetails")
};

function selectedStatusUrl(selectedStatus: any) {
  let selectedStatusUrl = "";
  for (var i = 0; i < selectedStatus.length; i++) {
    selectedStatusUrl += "&status[]=" + selectedStatus[i];
  }
  return selectedStatusUrl;
}

function selectedTagUrl(selectedTag: any) {
  let selectedTagUrl = "";
  for (var i = 0; i < selectedTag.length; i++) {
    selectedTagUrl += "&tags[]=" + selectedTag[i];
  }
  return selectedTagUrl;
}

const Gst = {
  addGst: (gstin: string) => requests.post("/gst", { gstin }),
  getAll: (
    skip: number,
    limit: number,
    searchText: string,
    download: boolean,
    type: string,
    tags: string[]
  ) =>
    download === false
      ? requests.get(
          `gstin/?skip=${skip}&limit=${limit}&searchText=${searchText}&download=${download}&type=${type}${selectedTagUrl(
            tags
          )}`
        )
      : requests.getBlob(
          //by default all the records are downloaded, concept of skip and limit is not considered at the time of downloading
          `gstin/?skip=${skip}&limit=${limit}&searchText=${searchText}&download=${download}&type=${type}${selectedTagUrl(
            tags
          )}`,
          {
            responseType: "blob"
          }
        ),
  getGst: (gstin: string) => requests.get(`/gsts/${gstin}`),
  addmultipleGst: (gstin: string[], tags: string[]) =>
    requests.post("/gstin/importrequest", { gstin, tags }),
  getGstCredit: () => requests.get("/gstin/gstincredits"),
  changeName: (body: { id: string; name: string }) =>
    requests.put("/gsts/changeName", body),
  deleteTransaction: (id: string) => requests.delete(`/gstin/?gstinId=${id}`),
  updateGSTIN: (gstinArray: string[]) =>
    requests.post("gstin/updategstindetails", { gstinArray })
};

const Tag = {
  addTag: (body: { name: string; description: string }) =>
    requests.post("/tag/add", body),
  getAll: () => requests.get("/tag/taglist")
  /* getGst: (gstin: string) => requests.get(`/gsts/${gstin}`), */
  /* changeName: (body: { id: string; name: string }) => requests.put("/gsts/changeName", body) */
};

const QRMP = {
  updateQRMP: (body: { quarter: string; GSTIN: string[] }) =>
    requests.put("qrmp/updateqrmp", body),
  getAll: (
    quarter: string,
    download: boolean,
    status: string[],
    tags: string[],
    searchText: string,
    skip: number,
    limit: number
  ) =>
    download === false
      ? requests.get(
          `qrmp/listqrmp?skip=${skip}&limit=${limit}&quarter=${quarter}&searchText=${searchText}&download=${download}${selectedStatusUrl(
            status
          )}${selectedTagUrl(tags)}`
        )
      : requests.getBlob(
          //by default all the records are downloaded, concept of skip and limit is not considered at the time of downloading
          `qrmp/listqrmp?skip=${skip}&limit=${limit}&quarter=${quarter}&searchText=${searchText}&download=${download}${selectedStatusUrl(
            status
          )}${selectedTagUrl(tags)}`,
          {
            responseType: "blob"
          }
        )
  /* getAll: (body: { quarter: string; download: boolean }) =>
    requests.post("/qrmp/listqrmp", body) */
  /* getGst: (gstin: string) => requests.get(`/gsts/${gstin}`), */
  /* changeName: (body: { id: string; name: string }) => requests.put("/gsts/changeName", body) */
};

const Rstatus = {
  updateRstatus: (body: { period: string; task: string; gstin: [] }) =>
    requests.put("retstatus/updatestatus", body),
  getAll: (
    period: string,
    task: string,
    download: boolean,
    status: string[],
    tags: string[],
    searchText: string,
    skip: number,
    limit: number
  ) =>
    download === false
      ? requests.get(
          `/retstatus/listgstinforreturn?skip=${skip}&limit=${limit}&task=${task}&period=${period}&searchText=${searchText}&download=${download}${selectedStatusUrl(
            status
          )}${selectedTagUrl(tags)}`
        )
      : requests.getBlob(
          //by default all the records are downloaded, concept of skip and limit is not considered at the time of downloading
          `/retstatus/listgstinforreturn?skip=${skip}&limit=${limit}&task=${task}&period=${period}&searchText=${searchText}&download=${download}${selectedStatusUrl(
            status
          )}${selectedTagUrl(tags)}`,
          {
            responseType: "blob"
          }
        )
  /* : (body: {
    period: string;
    task: string;
    download: boolean;
    status: string[];
    tags: string[];
  }) => requests.post("/retstatus/listgstinforreturn?skip=0&limit=10000", body),

  getfile: () =>
    requests.getBlob("/retstatus/listgstinforreturn1", {
      responseType: "blob"
    }) */
  /* getGst: (gstin: string) => requests.get(`/gsts/${gstin}`), */
  /* changeName: (body: { id: string; name: string }) => requests.put("/gsts/changeName", body) */
};

const Payment = {
  process: (
    amount: number,
    planId: number,
    GSTIN: string,
    legalName: string,
    address: string[],
    paymentType: string,
    credits: number,
    expiryDate: string
  ) =>
    requests.post("/payment", {
      amount,
      planId,
      GSTIN,
      legalName,
      address,
      paymentType,
      credits,
      expiryDate
    }),
  getPaymentStatus: (order: string) => requests.get(`/order/?orderId=${order}`),
  updateOrder: (data: {
    orderId: string;
    razorpayPaymentId: string;
    razorpaySignature: string;
    razorpayOrderId: string;
  }) => {
    return requests.patch(`/payment/updateorder`, data);
  }
};

const Subscription = {
  currentPlan: () => requests.get("payment/currentsubscription"),
  allPlan: (purchaseType: string) =>
    requests.get(`plan/list?purchaseType=${purchaseType}`),
  gstinDetails: (gstin: string) =>
    requests.get(`plan/gstinDetails?gstin=${gstin}`),
  payamount: (planId: number, credits: number, purchaseType: string) =>
    requests.get(
      `plan/calculateprice?planId=${planId}&credits=${credits}&purchaseType=${purchaseType}`
    )
};

const Request = {
  getRequestStatus: (importrequestid: string) =>
    requests.get(`/gstin/status?importrequestid=${importrequestid}`),
  getAll: (type: string, skip: number, limit: number) =>
    requests.get(
      `/gstin/importrequest?skip=${skip}&limit=${limit}&type=${type}`
    )
};

const Sales = {
  import: (file: any) => requests.post("/sales/import", file),
  startImport: (importRequest: string) =>
    requests.post("/sales/import/start", { importRequest }),
  getImportRequests: (gstId: string, year: string, month: string) =>
    requests.get(
      `/sales/import/requests?gstId=${gstId}&year=${year}&month=${month}`
    ),
  deleteImportData: (importRequest: string) =>
    requests.delete(`/sales/import/deleteFile?importRequest=${importRequest}`),
  getSummary: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/summary?month=${month}&year=${year}`),
  deleteTransaction: (id: string) => requests.delete(`/sales/${id}`),
  getTransactions: (skip: string, limit: string) =>
    requests.get(`/gstin?skip=${skip}&limit=${limit}`),
  getSpecificTransactions: (
    // month: string,
    // year: string,
    skip: string,
    limit: string

    // platform: string
  ) => requests.get(`/sales/gst?skip=${skip}&limit=${limit}`),
  getReturnTransactions: (
    // year: string,
    skip: string,
    limit: string
    // gstId: string
  ) => requests.get(`/sales/gst?skip=${skip}&limit=${limit}`),
  getSpecificReturnTransactions: (
    // month: string,
    // year: string,
    skip: string,
    limit: string
    // gstId: string,
    // platform: string
  ) => requests.get(`/sales/gst?month=skip=${skip}&limit=${limit}`),
  exportSalesTransactions: (
    // month: string,
    // year: string,
    skip: string,
    limit: string
    // gstId: string,
    // transactionType: string
  ) =>
    requests.getBlob(
      `/sales/gst/downloadTransaction?skip=${skip}&limit=${limit}`,
      {
        responseType: "blob"
      }
    ),
  exportSpecificSalesTransactions: (
    // month: string,
    // year: string,
    skip: string,
    limit: string
    // gstId: string,
    // platform: string,
    // transactionType: string
  ) =>
    requests.getBlob(
      `/sales/gst/downloadTransaction?skip=${skip}&limit=${limit}`,
      {
        responseType: "blob"
      }
    )
};

const Purchase = {
  import: (file: any) => requests.post("/purchase/import", file),
  startImport: (importRequest: string) =>
    requests.post("/purchase/import/start", { importRequest }),
  getImportRequests: (gstId: string, year: string, month: string) =>
    requests.get(
      `/purchase/import/requests?gstId=${gstId}&year=${year}&month=${month}`
    ),
  deleteImportData: (importRequest: string) =>
    requests.delete(
      `/purchase/import/deleteFile?importRequest=${importRequest}`
    ),
  getSummary: (month: string, year: string, gstId: string) =>
    requests.get(
      `/purchase/summary?month=${month}&year=${year}&gstId=${gstId}`
    ),
  getTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=Purchase`
    ),
  getSpecificTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=Purchase&platform=${platform}`
    ),
  deleteTransaction: (id: string) => requests.delete(`/purchase/${id}`),
  getReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${encodeURI(
        "Purchase Return"
      )}`
    ),
  getSpecificReturnTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string
  ) =>
    requests.get(
      `/purchase/gst?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${encodeURI(
        "Purchase Return"
      )}&platform=${platform}`
    ),
  exportPurchaseTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/purchase/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}
    `,
      {
        responseType: "blob"
      }
    ),
  exportSpecificPurchaseTransactions: (
    month: string,
    year: string,
    skip: string,
    limit: string,
    gstId: string,
    platform: string,
    transactionType: string
  ) =>
    requests.getBlob(
      `/purchase/gst/downloadTransaction?month=${month}&year=${year}&skip=${skip}&limit=${limit}&gstId=${gstId}&transactionType=${transactionType}&platform=${platform}
  `,
      {
        responseType: "blob"
      }
    )
};

const Returns = {
  iffData: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/iff?month=${month}&year=${year}&gstId=${gstId}`),
  iffJson: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/iffJson?month=${month}&year=${year}&gstId=${gstId}`),
  gstr1Data: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/gstr1?month=${month}&year=${year}&gstId=${gstId}`),
  gstr1Json: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/gstr1Json?month=${month}&year=${year}&gstId=${gstId}`),
  gstr3bData: (month: string, year: string, gstId: string) =>
    requests.get(`/sales/gstr3b?month=${month}&year=${year}&gstId=${gstId}`),
  gstr3bJson: (month: string, year: string, gstId: string) =>
    requests.get(
      `/sales/gstr3bjson?month=${month}&year=${year}&gstId=${gstId}`
    ),
  gstr2import: (file: any) => requests.post("/gstr2/import", file),
  startGstr2Import: (importRequest: string) =>
    requests.post("/gstr2/import/start", { importRequest }),
  getGstr2ImportRequests: (gstId: string, year: string, month: string) =>
    requests.get(
      `gstr2/import/requests?gstId=${gstId}&year=${year}&month=${month}`
    ),
  gstr2distinctTypes: (month: string, year: string, gstId: string) =>
    requests.get(
      `gstr2/distinctTypes?month=${month}&year=${year}&gstId=${gstId}`
    ),
  gstr2Data: (
    month: string,
    year: string,
    gstId: string,
    skip: string,
    limit: string,
    platform: string,
    type: string
  ) =>
    requests.get(
      `gstr2/transactions?month=${month}&year=${year}&gstId=${gstId}&skip=${skip}&limit=${limit}&platform=${platform}&type=${type}`
    ),
  getGstr2Summary: (month: string, year: string, gstId: string) =>
    requests.get(`/gstr2/summary?month=${month}&year=${year}&gstId=${gstId}`),
  bulkCopy: (
    month: string,
    year: string,
    gstId: string,
    platforms: [],
    types: []
  ) =>
    requests.put(
      `/gstr2/bulkCopyTransaction?gstId=${gstId}&month=${month}&year=${year}`,
      { gstId, month, year, platforms, types }
    ),
  copyToPurchase: (id: string, copied: boolean) =>
    requests.put(`gstr2/${id}/copied`, { copied }),
  itcClaimCount: (gstId: string, month: string, year: string) =>
    requests.get(
      `/purchase/getNoOfTransactions?gstId=${gstId}&month=${month}&year=${year}`
    ),
  get2APurchases: (
    gstId: string,
    month: string,
    year: string,
    transactionType: string,
    skip?: string,
    limit?: string
  ) =>
    requests.get(
      `/purchase/2aTransaction?gstId=${gstId}&month=${month}&year=${year}&transactionType=${transactionType}&skip=${skip}&limit=${limit}`
    ),
  get2BPurchases: (
    gstId: string,
    month: string,
    year: string,
    transactionType: string,
    skip?: string,
    limit?: string
  ) =>
    requests.get(
      `/purchase/2bTransaction?gstId=${gstId}&month=${month}&year=${year}&transactionType=${transactionType}&skip=${skip}&limit=${limit}`
    ),
  getUnclaimedPurchases: (
    gstId: string,
    month: string,
    year: string,
    transactionType: string,
    skip?: string,
    limit?: string
  ) =>
    requests.get(
      `/purchase/normalTransactions?gstId=${gstId}&month=${month}&year=${year}&transactionType=${transactionType}&skip=${skip}&limit=${limit}`
    ),
  claimItc: (purchaseId: string, claim: boolean, month: string, year: string) =>
    requests.put(`/purchase/${purchaseId}/claimItc`, { claim, month, year })
};

const common = {
  maintenanceStatus: () => requests.get("/maintenancestatus")
};

let agent = {
  setToken: (_token: string | undefined) => (token = _token),
  Agency,
  Auth,
  Gst,
  Tag,
  QRMP,
  Rstatus,
  Payment,
  Subscription,
  Request,
  Sales,
  Purchase,
  Returns,
  common
};

export default agent;
