const AgencyClients: string = "/rstatus";
const SignUpCompleted: string = "/sign-up-completed";
const AddGST: string = "/gsts/add";

let routes = {
  AgencyClients,
  SignUpCompleted,
  AddGST
};

export default routes;
