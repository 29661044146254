import React from "react";
import Dashboard from "../../components/Dashboard";
import routes from "../../constants/routes";
import Icon from "../../components/Icon";
import agent from "../../agent";
import { WithRouterProps, withRouter } from "../../helpers/withRouter";
import { compose } from "redux";

class Report extends React.Component<WithRouterProps> {
  state: { loading: boolean; source: string; clickId: string };

  constructor(props: WithRouterProps) {
    super(props);

    this.state = {
      loading: false,
      source: "",
      clickId: ""
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getLocalStorage();
    }, 1000);
    setTimeout(() => {
      this.updateUser();
    }, 1500);
    setTimeout(() => {
      this.props.navigate?.(routes.AddGST);
    }, 2100);
  }

  updateUser = () => {
    this.setState({ loading: true });
    let source = this.state.source;
    let clickId = this.state.clickId;
    agent.Auth.updateUser(source, clickId)
      .then((response: any) => {
        this.setState({
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
      })
      .finally(() => {
        this.removeLocalStorage();
      });
  };

  getLocalStorage = () => {
    this.setState({
      source: localStorage.getItem("source") || "",
      clickId: localStorage.getItem("clickId") || ""
    });
  };

  removeLocalStorage = () => {
    localStorage.removeItem("source");
    localStorage.removeItem("clickId");
  };

  render() {
    return (
      <Dashboard>
        <h1 className="text-2xl font-semibold text-gray-900">
          Signup Completed
        </h1>
        <div className="mt-8">
          <p className="text-lg text-black">
            Thanks for signing up for TaxPido Return Status Checker. We are
            setting up your account You will be redirected in couple of seconds.
          </p>
          <div className="mt-2">
            <Icon name="loading-dark" className="fill-black" />
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(withRouter)(Report);
