import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import agent from "../agent";
import Icon from "../components/Icon";
import baseConfig from "../config";
import routes from "../constants/routes";
import { validEmail, validPassword, validNumbers } from "../helpers/regex";
import { ADD_NOTIFICATION, LOGIN } from "../store/types";
import TagManager from "react-gtm-module";
import { WithRouterProps } from "../helpers/withRouter";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "signin"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification
});

const mapDispatchToProps = (dispatch: any) => ({
  onLogin: (token: string) => dispatch({ type: LOGIN, payload: { token } }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector> & Partial<WithRouterProps>
;

//Main Class
class Login extends React.Component<PropsFromRedux> {
  // Type declaration for each State inside class
  state: {
    email: string;
    password: string;
    logging: boolean;
    isVerified: boolean;
    emailErr: boolean;
    passwordErr: boolean;
    iconType: string;
    inputType: string;
    otpLogin: boolean;
    otp: string | undefined;
    otpErr: boolean;
    source: string;
    clickId: string;
  };

  constructor(props: PropsFromRedux) {
    super(props);

    // Initializing State
    this.state = {
      email: "",
      password: "",
      logging: false,
      isVerified: true, //should be reversed to false once Recaptch is setup correctly
      emailErr: false,
      passwordErr: false,
      iconType: "eye-open",
      inputType: "password",
      otpLogin: false,
      otp: undefined,
      otpErr: false,
      source: "",
      clickId: ""
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    const url = "https://www.google.com/recaptcha/api.js?render=";
    script.src = url + process.env.REACT_APP_RECAPTCHA_KEY;
    script.async = true;

    this.setState({
      source: window.location.href.includes("fbclid")
        ? "facebook"
        : window.location.href.includes("gclid")
        ? "google"
        : "",
      clickId: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?") + 1)
        : ""
    });
    document.body.appendChild(script);

    setTimeout(() => {
      this.setLocalStorage();
    }, 1000);
  }

  // On Every chnage update state
  updateState = (field: string) => (ev: any) => {
    this.setState({
      [field]: ev.target.value
    });
    //Testing if values of Entries are valid
    if (field === "email") {
      if (validEmail.test(ev.target.value)) {
        this.setState({ emailErr: false });
      }
    } else if (field === "password") {
      if (validPassword.test(ev.target.value)) {
        this.setState({ passwordErr: false });
      }
    } else if (field === "otp") {
      if (validNumbers.test(ev.target.value)) {
        this.setState({ otpErr: false });
      }
    }
  };

  // Testing if values entered are invaid onBlur
  onBlurEmail = (e: any) => {
    if (!validEmail.test(e.target.value)) {
      this.setState({ emailErr: true });
    }
  };

  onBlurPassword = (e: any) => {
    if (!e.target.value) {
      this.setState({ passwordErr: true });
    }
  };

  onViewPassword = () => {
    if (this.state.inputType === "password") {
      this.setState({ inputType: "text", iconType: "eye-close" });
    } else {
      this.setState({ inputType: "password", iconType: "eye-open" });
    }
  };

  // Recaptcha Functions
  onVerify = (token: any) => {
    token
      ? this.setState({
          isVerified: true
        })
      : (this.props as any)
          .onNotify(
            "Recaptcha Not Verified...",
            "Your Recaptcha is not verified. Please verify again to proceed.",
            "danger"
          )
          .then(
            this.setState({
              isVerified: false
            })
          );
  };

  //Enable signin button ony when all conditions satisfy
  isSignInEnabled = () => {
    if (this.state.otpLogin === false)
      return (
        this.state.email &&
        !this.state.emailErr &&
        this.state.password &&
        !this.state.passwordErr &&
        this.state.isVerified
      );
    if (this.state.otpLogin === true)
      return (
        this.state.email &&
        !this.state.emailErr &&
        this.state.otp &&
        !this.state.otpErr &&
        this.state.isVerified
      );
  };

  // On Login button click
  login = () => {
    this.setState({ logging: true });
    agent.Auth.login(this.state.email, this.state.password)
      .then((response: any) => {
        this.setState({ logging: false });
        (this.props as any).onLogin(response.token);
        this.props.navigate?.(routes.AgencyClients);
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        err.response.data.message ===
        "You email is not verified, Please check for verification mail in your email."
          ? this.props.navigate?.(
              `/verify/signup/otp/${err?.response?.data?._id}`
            )
          : (this.props as any).onNotify(
              "Incorrect Details",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
      });
  };

  loginOTPRequest = () => {
    this.setState({ logging: true });
    agent.Auth.loginOTPRequest(this.state.email)
      .then((response: any) => {
        this.setState({ logging: false, otpLogin: true });
        (this.props as any).onNotify(
          "Successful",
          "OTP has been sent successfully to your email",
          "success"
        );
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).onNotify(
          "Unable to send OTP",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  loginViaOTP = () => {
    this.setState({ logging: true });
    const otp = parseInt(this.state.otp as string, 10);
    agent.Auth.loginViaOTP(this.state.email, otp)
      .then((response: any) => {
        this.setState({ logging: false });
        (this.props as any).onLogin(response.token);
        this.props.navigate?.(routes.AgencyClients);
      })
      .catch((err: any) => {
        this.setState({ logging: false });
        (this.props as any).onNotify(
          "Failed!",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  otpLogin = (value: boolean) => {
    this.setState({ otpLogin: value });
  };

  setLocalStorage = () => {
    localStorage.setItem("source", this.state.source);
    localStorage.setItem("clickId", this.state.clickId);
  };

  // Main Component render
  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/images/Taxpido_logo_black.png"
            alt="Welcome to RSC by TaxPido"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your RSC account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            or&nbsp;
            <Link
              to="/signup"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Create new account
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address*
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onBlur={this.onBlurEmail}
                    value={this.state.email}
                    onChange={this.updateState("email")}
                    autoComplete="email"
                    required
                    onKeyPress={(e: any) => {
                      if (this.isSignInEnabled() && e.charCode === 13) {
                        this.login();
                      } else {
                        this.onBlurEmail(e);
                      }
                    }}
                    className={
                      this.state.emailErr
                        ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md"
                        : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                    }
                  />
                  {
                    // On Error gives red warning
                    this.state.emailErr && (
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )
                  }
                </div>
                {this.state.emailErr ? (
                  <div
                    className="absolute mt-1 text-sm text-red-600"
                    id="email-error"
                  >
                    Invalid Email
                  </div>
                ) : null}
              </div>
              {/* Password */}
              <div>
                <div className="grid-cols-2 grid">
                  <div className="justify-self-start mt-2">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {this.state.otpLogin ? "Email OTP*" : "Password*"}
                    </label>
                  </div>
                  {this.state.email === null || this.state.email === "" ? (
                    <div></div>
                  ) : this.state.emailErr === true ? (
                    <div></div>
                  ) : this.state.otpLogin ? (
                    <div className="text-sm justify-self-end">
                      <button
                        onClick={() => {
                          this.otpLogin(false);
                        }}
                        disabled={this.state.logging}
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Login via Password
                      </button>
                    </div>
                  ) : (
                    <div className="text-sm justify-self-end">
                      <button
                        onClick={() => {
                          this.loginOTPRequest();
                        }}
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                        disabled={this.state.logging}
                      >
                        Login via Email OTP
                      </button>
                    </div>
                  )}
                </div>
                {this.state.otpLogin === false ? (
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="flex">
                      <input
                        id="password"
                        name="password"
                        type={this.state.inputType}
                        onBlur={this.onBlurPassword}
                        onChange={this.updateState("password")}
                        autoComplete="current-password"
                        onKeyPress={(e: any) => {
                          if (this.isSignInEnabled() && e.charCode === 13) {
                            this.login();
                          }
                        }}
                        required
                        className={
                          this.state.otpErr
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md"
                            : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                        }
                      />
                      <button onClick={this.onViewPassword}>
                        <Icon
                          name={this.state.iconType}
                          className="h-6 w-6 text-gray-500 absolute top-2 right-3"
                        />
                      </button>
                    </div>
                    {this.state.passwordErr && (
                      <div className="absolute inset-y-0 right-6 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="flex">
                      <input
                        id="emailOTP"
                        name="emailOTP"
                        onChange={this.updateState("otp")}
                        onKeyPress={(e: any) => {
                          if (this.isSignInEnabled() && e.charCode === 13) {
                            this.loginViaOTP();
                          }
                        }}
                        required
                        className={
                          this.state.otpErr
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:border-red-500 sm:text-sm rounded-md"
                            : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm"
                        }
                      />
                    </div>
                    {this.state.otpErr && (
                      <div className="absolute inset-y-0 right-6 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon
                          className="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </div>
                )}
                {this.state.otpLogin === false && this.state.passwordErr ? (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Please enter a password
                  </p>
                ) : this.state.otpLogin === true && this.state.otpErr ? (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Please enter email OTP
                  </p>
                ) : null}
              </div>
              {/* Forgot Password */}
              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>

                {this.state.otpLogin === false ? (
                  <div className="text-sm">
                    <Link
                      to="/forgetPassword"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                ) : (
                  <div className="text-sm">
                    <button
                      onClick={() => {
                        this.loginOTPRequest();
                      }}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                      disabled={this.state.logging}
                    >
                      Resend OTP
                    </button>
                  </div>
                )}
              </div>
              {/* Recaptcha v3 */}
              <GoogleReCaptchaProvider
                useRecaptchaNet={true}
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                scriptProps={{ async: true, defer: true, appendTo: "body" }}
              >
                <GoogleReCaptcha onVerify={this.onVerify} />
              </GoogleReCaptchaProvider>
              {/* SignIn */}
              <div>
                <button
                  onClick={this.state.otpLogin ? this.loginViaOTP : this.login}
                  onKeyPress={(e: any) => {
                    if (e.charCode === 13) {
                      this.state.otpLogin ? this.loginViaOTP() : this.login();
                    }
                  }}
                  disabled={!this.isSignInEnabled() as boolean}
                  className={
                    this.isSignInEnabled()
                      ? "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      : "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-300 cursor-not-allowed"
                  }
                >
                  {/* Loading animation */}
                  {this.state.logging ? <Icon name="loading" /> : null}
                  Sign in
                </button>
              </div>
            </div>
            {/* Signin With Google */}
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 ">
                <a href={baseConfig.baseURL + "/auth/googleSignup"}>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center items-center py-2.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                  >
                    <span className="sr-only text-gray-500">
                      Sign in with Google
                    </span>
                    <Icon
                      name="solid/google"
                      className="w-5 h-5 text-gray-500"
                    />
                    <span className="text-gray-500 text-sm">
                      &nbsp; Sign in with Google
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(Login);
